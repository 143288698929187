import {themeComplement} from '../../../admin/AppAdmin/css/theme';

export default theme => ({
  errorIcon: {
    width: '4em',
    height: '4em',
  },
  errorContainer: {
    background: 'url(' + themeComplement.palette.backgroundPath + ')',
    backgroundSize: 'cover',
    height: '100vh',
  },
  paper: {
    width: '20%',
    padding: '2em',
  },
  title: {
    fontSize: '3rem',
  },
  subtitle: {
    fontSize: '1.75rem',
  }
})
