import {themeComplement} from './theme';

export default theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
  },
  offline: {
    zIndex: 2,
    padding: "1em",
    backgroundColor: themeComplement.palette.notifications.error.color,
    color: "#FFFFFF",
    fontWeight: "bold",
    position: "fixed",
    textAlign: "center",
    bottom: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    transition: 'all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    '& svg': {
      verticalAlign: "middle",
      marginRight: "0.3em",
    },
    '& span': {
      verticalAlign: "bottom",
      textTransform: "uppercase",
    },
  },
  height100: {
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minWidth: 0,
    marginLeft: themeComplement.palette.leftBarDefaultWidth,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentCollapsedLeftMargin: {
    marginLeft: themeComplement.palette.leftBarCollapsedWidth,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: theme.mixins.toolbar,
  errorPage: {
    width: '25%',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
});
