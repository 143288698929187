import {MenuItem, Select, DialogContent, DialogTitle, Grid, IconButton, withStyles, ListSubheader} from '@material-ui/core'
import ImportExport from '@material-ui/icons/ImportExport'
import Modal from '@oniti/oniti-modal'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import ExportsCss from './css/ExportsCss'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { collectionActions } from '../../../reducers/collectionsReducer'


class Exports extends Component {
  state = {
    open: false,
    type: 'inscriptions',
    exportType: 'nouveautes',
    week: 'null', // must be a non-empty string, it seems :/
    navette: 'null',
  };

  fetchWeeks() {
    let {dispatch} = this.props;
    collectionActions(
      dispatch,
      'weeks',
      'INDEX',
    )
  }

  fetchNavettes() {
    let {dispatch} = this.props;
    collectionActions(
      dispatch,
      'navettes',
      'INDEX',
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.type !== this.state.type && (
      this.state.type === 'fiche-sanitaire' || this.state.type === 'feuilles-animateurs' || this.state.type === 'feuilles-pointage' || this.state.type === 'listing-navette'
    )) {
      this.fetchWeeks()
    }

    if (prevState.type !== this.state.type && this.state.type === 'listing-navette') {
      this.fetchNavettes()
    }
  }

  /**
   * Ouverture de la modale de choix d'export
   * @return {[type]} [description]
   */
  onClickHandler() {
    this.setState({open: true})
  }

  /**
   * Soumission du choix d'export
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {type, exportType, week} = this.state
    const noCache = new Date().getTime(); // Add a parameter to avoid HTTP cache
    let url = process.env.REACT_APP_API_URL + '/exports?type=' + type + '&exportType=' + exportType + '&week=' + week + '&navette=' + this.state.navette + '&noCache=' + noCache

    window.open(url, '_blank')
    this.setState({open: false})
  }

  /**
   * Retourne la modale de choix d'export
   * @return {[type]} [description]
   */
  getModal() {
    const {open, type} = this.state
    const {classes} = this.props

    if (!open) return null;

    return (
      <Modal
        openModal={open}
        onCloseHandler={() => this.setState({open: false})}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <ImportExport/> <span>Exports</span>
        </DialogTitle>
        <DialogContent key="content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                value={type}
                onChange={event => this.setState({type: event.target.value})}
                fullWidth
              >
                <ListSubheader className={classes.listSubheader}>Exports simples</ListSubheader>
                <MenuItem value={'inscriptions'} className={classes.menuItem}>Inscriptions</MenuItem>
                <MenuItem value={'decus'} className={classes.menuItem}>Déçus</MenuItem>
                <MenuItem value={'tous'} className={classes.menuItem}>Tous séjours</MenuItem>
                <MenuItem value={'paiements'} className={classes.menuItem}>Paiements</MenuItem>
                <MenuItem value={'stats'} className={classes.menuItem}>Statistiques</MenuItem>
                <ListSubheader className={classes.listSubheader}>Exports avancés</ListSubheader>
                <MenuItem value={'liste-globale'} className={classes.menuItem}>Liste globale des inscriptions</MenuItem>
                <MenuItem value={'fiche-sanitaire'} className={classes.menuItem}>Fiche sanitaire</MenuItem>
                <MenuItem value={'feuilles-animateurs'} className={classes.menuItem}>Feuilles animateurs (ZIP)</MenuItem>
                <MenuItem value={'feuilles-pointage'} className={classes.menuItem}>Feuilles de pointage (ZIP)</MenuItem>
                <MenuItem value={'listing-navette'} className={classes.menuItem}>Listing navette</MenuItem>
              </Select>
            </Grid>
            { type === 'liste-globale' && <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="export-type"
                    name="export-type"
                    value={this.state.exportType}
                    onChange={event => this.setState({ exportType: event.target.value })}
                  >
                    <FormControlLabel value="nouveautes" control={<Radio />} label="Export nouveautés uniquement" />
                    <FormControlLabel value="complet" control={<Radio />} label="Export complet" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            }
            { (type === 'fiche-sanitaire' || type === 'feuilles-animateurs' || type === 'feuilles-pointage' || type === 'listing-navette')
              && this.props.weeksStore.list &&
              <Grid item xs={12}>
                <Select
                  value={this.state.week}
                  onChange={event => this.setState({week: event.target.value})}
                  fullWidth
                >
                  <MenuItem value={'null'} disabled>- Choisir une semaine</MenuItem>
                  {this.weeksItems()}
                </Select>
              </Grid>
            }
            { type === 'listing-navette' && this.props.navettesStore.list &&
              <Grid item xs={12}>
                <Select
                  value={this.state.navette}
                  onChange={event => this.setState({navette: event.target.value})}
                  fullWidth
                >
                  <MenuItem value={'null'} disabled>- Choisir une navette</MenuItem>
                  {this.navettesItems()}
                </Select>
              </Grid>
            }
          </Grid>
        </DialogContent>
      </Modal>
    );
  }

  weeksItems() {
    const list = this.props.weeksStore.list;

    return Object.keys(list).map((id) => {
      return <MenuItem key={id} value={id}>{list[id].libelle}</MenuItem>
    });
  }

  navettesItems() {
    const navettes = this.props.navettesStore.list;

    let items = [];
    navettes.forEach((navette, index) => {
      items.push(<ListSubheader key={`circuit-${index}`}>{navette.circuit}</ListSubheader>);
      Object.keys(navette.arrets_array).forEach((arret_id) => {
        items.push(<MenuItem key={arret_id} value={arret_id}>{navette.arrets_array[arret_id]}</MenuItem>);
      });
    });

    return items;
  }

  render() {
    const {classes} = this.props

    return <>
      <IconButton
        onClick={this.onClickHandler.bind(this)}
        title="Exports"
      >
        <ImportExport className={classes.icon}/>
      </IconButton>
      {this.getModal()}
    </>
  }
}

Exports = withStyles(ExportsCss)(Exports);

Exports = connect((store) => {
  return {
    weeksStore: store.collections.weeks,
    navettesStore: store.collections.navettes,
  }
})(Exports)

export default Exports
