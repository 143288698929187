import {Grid, IconButton, Typography, withStyles} from '@material-ui/core'

import React, {Component} from 'react'
import {connect} from 'react-redux'
import CollectionCrud from '../../common/Components/CollectionCrud'
import { collectionActions } from '../../../reducers/collectionsReducer'
import NavettesCss from './css/NavettesCss'
import { ImportExport } from '@material-ui/icons'


class Navettes extends Component {
  componentDidMount() {
    let {dispatch} = this.props;

    collectionActions(
      dispatch,
      'navettes',
      'INDEX',
    )
  }

  onExportHandler(line) {
    let url = process.env.REACT_APP_API_URL + '/exports/navettes/' + line.uuid

    window.open(url, '_blank')
  }

  getActionsButtons(line) {
    var btns = []

    btns.push(
      <IconButton
        aria-label="Export"
        key="export"
        color="primary"
        title="Export des passagers"
        onClick={this.onExportHandler.bind(this, line)}
      >
        <ImportExport/>
      </IconButton>
    )
    return btns
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container spacing={2} className={classes.container}>
        <Typography
          variant="button"
          gutterBottom
          className={classes.sectionTitle}
        >
          Navettes
        </Typography>

        <CollectionCrud
          collectionName={'navettes'}
          loadDatas={false}
          showBtnEdit={false}
          showBtnAdd={false}
          showBtnDelete={false}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            rowsPerPageOptions: [20, 50, 100],
          }}
          cellsConfig={[
            {
              datakey: 'circuit',
              title: 'Circuit',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'arrets_string',
              title: 'Arrêts',
              sortable: false,
              searchable: true,
            },
            {
              datakey: 'places',
              title: 'Places',
              sortable: false,
              searchable: false,
            },
            {
              format: this.getActionsButtons.bind(this),
              sortable: false,
            },
          ]}
        />
      </Grid>
    );
  }

}

Navettes = withStyles(NavettesCss)(Navettes)

Navettes = connect((store) => {
  return {
    navettesStore: store.collections.navettes,
  }
})(Navettes);

export default Navettes
