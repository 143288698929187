import {DialogContent, DialogTitle, Grid, IconButton, Typography, withStyles} from '@material-ui/core'
import Loop from '@material-ui/icons/Loop'
import Modal from '@oniti/oniti-modal'
import React, {Component} from 'react'
import MultiplesCss from './css/MultiplesCss'
import { connect } from 'react-redux'
import {multiplesCampagne} from '../../common/App/reducers/appReducer'


class Multiples extends Component {
  state = {
    open: false,
  };

  /**
   * Ouverture de la modale de confirmation
   */
  onClickHandler() {
    this.setState({open: true})
  }

  /**
   * Soumission du choix d'export
   */
  onSubmitHandler() {
    multiplesCampagne(this.props.dispatch, this.props.campagneStore.uuid, () => {
      this.setState({open: false})
    })
  }

  /**
   * Retourne la modale de choix d'export
   */
  getModal() {
    const {open} = this.state

    if (!open) return null;

    return (
      <Modal
        openModal={open}
        onCloseHandler={() => this.setState({open: false})}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <Loop/> <span>Inscriptions multiples</span>
        </DialogTitle>
        <DialogContent key="content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography component="p">
                A partir de maintenant, vous autorisez de nouvelles inscriptions, même pour les enfants déjà inscrits. Confirmez-vous ce choix ?
            </Typography>
            <Typography component="p" className={this.props.classes.postScriptum}>
                <b>Attention</b> : Cette action est irréversible. Pensez à vérifier les dates d'ouverture et de fermeture des inscriptions.
            </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Modal>
    );
  }

  render() {
    const {classes} = this.props

    return <>
      <IconButton
        onClick={this.onClickHandler.bind(this)}
        title="Autoriser de nouvelles inscriptions multiples"
      >
        <Loop className={classes.icon}/>
      </IconButton>
      {this.getModal()}
    </>
  }
}

Multiples = withStyles(MultiplesCss)(Multiples);

Multiples = connect(store => ({
  campagneStore: store.app.campagne,
}))(Multiples)

export default Multiples
