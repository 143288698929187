import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid, MuiThemeProvider, withStyles} from '@material-ui/core';
import {Redirect, Switch, withRouter} from 'react-router-dom';
import AppAdminCss from "./css/AppAdminCss";
import TopBar from './TopBar';
import LeftMenu from "./LeftMenu";
import Notification from '../../common/App/Notification';
import {getExpirationFromCookie, logout, redirectLoginDone, setAuthExpiration} from '../../../reducers/authReducer';
import {addNotification} from '../../../reducers/notificationReducer';
import {theme, themeComplement} from './css/theme';
import {classesNames, hasRightsForRoute} from '../../common/Tools/Tools';
import {Warning} from '@material-ui/icons'
import {Route} from 'react-router';
import Error404 from '../../common/ErrorsPages/Error404';
import Error403 from '../../common/ErrorsPages/Error403';
import {routes} from './routes/routes';
import RouterConfig from '../../../router/config';
import moment from 'moment';

class AppAdmin extends Component {
  state = {
    goToLogin: false,
    leftMenuOpen: false,
    intervalLogout: null,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.goToLogin) {
      if (nextProps.location[RouterConfig.pathname] !== RouterConfig.prefixRoutes + "/admin/auth") nextProps.history.push("/admin/auth");
      redirectLoginDone(nextProps.dispatch)
    }

    // Gère la déconnection en fonction de l'expiration
    if (nextProps.user && nextProps.authExpiration && !prevState.intervalLogout) {
      let intervalLogout = window.setInterval(() => {
        let cookieExpiration = getExpirationFromCookie(),
          propsExpiration = nextProps.authExpiration;

        if (cookieExpiration !== propsExpiration) setAuthExpiration(nextProps.dispatch);
        else if (moment().isAfter(moment(nextProps.authExpiration))) {
          logout(nextProps.dispatch);
          addNotification(nextProps.dispatch, {
            message: 'Votre session a expiré',
            bgColor: themeComplement.palette.notifications.error.color,
          });
          clearInterval(intervalLogout)
        }
      }, 60000);
      return {
        intervalLogout,
      }
    }

    return null;
  }

  handleLeftMenuToggle() {
    this.setState({leftMenuOpen: !this.state.leftMenuOpen});
  }

  /**
   * Gestion de la redirection sur le login pour les utilisateurs non authentifiés
   * @param r
   * @param props
   * @returns {*}
   */
  renderComponent(r, props) {
    const {user, classes} = this.props;
    if (r.public || hasRightsForRoute(r, user)) return <r.component {...props} />;
    else if (!!user && user.rights.indexOf(r.right) === -1) return <Error403 className={classes.errorPage}/>;
    else return <Redirect from={r.path} to="/admin/auth"/>;
  }

  getContent() {
    const {classes, currentRouteConfig} = this.props;

    let switchComponent = <Switch>
      {routes.map(r => <Route path={r.path} exact={r.exact} key={r.path} render={this.renderComponent.bind(this, r)}/>)}
      <Route component={Error404}/>
    </Switch>;

    if (currentRouteConfig.noContainer) {
      return (
        <div className={classes.root}>
          {switchComponent}
          <Notification/>
          {this.getOffLineInfo()}
        </div>
      );
    } else
      return (
        <main
          className={this.state.leftMenuOpen ? classes.content : classes.content + ' ' + classes.contentCollapsedLeftMargin}
        >
          <div className={classes.toolbar} style={{minHeight: '72px'}}/>
          <Grid
            container
            className={classes.root}
            style={{padding: theme.spacing(3)}}
          >
            {switchComponent}
          </Grid>
          <Notification/>
          {this.getOffLineInfo()}
        </main>
      );
  }

  /**
   * Retoune le Footer pour indiquer le mode déconnecté
   */
  getOffLineInfo() {
    const {online, classes} = this.props

    if (online) return null
    else return (
      <footer
        className={classes.offline}
        style={{width: !this.state.leftMenuOpen ? '100%' : 'calc(100% - ' + themeComplement.palette.leftBarDefaultWidth + 'px)'}}
      >
        <Warning/><span>Mode déconnecté</span>
      </footer>
    )
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    const {classes, currentRouteConfig} = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classesNames({[classes.root]: true, [classes.height100]: currentRouteConfig.noContainer})}>
          <TopBar leftMenuOpen={this.state.leftMenuOpen}
                  handleLeftMenuToggle={this.handleLeftMenuToggle.bind(this)}
                  noContainer={currentRouteConfig.noContainer}
          />
          <LeftMenu open={this.state.leftMenuOpen}
                    handleLeftMenuToggle={this.handleLeftMenuToggle.bind(this)}
                    noContainer={currentRouteConfig.noContainer}
          />
          {this.getContent()}
        </div>
      </MuiThemeProvider>
    );
  }
}

AppAdmin = connect((store) => {
  return {
    goToLogin: store.auth.goToLogin,
    location: store.router.location,
    user: store.auth.user,
    authExpiration: store.auth.expiration,
    currentRouteConfig: store.app.currentRouteConfig,
    online: store.app.online,
  }
})(AppAdmin);

AppAdmin = withStyles(AppAdminCss)(AppAdmin);
AppAdmin = withRouter(AppAdmin);

export default AppAdmin
