import React, {Component} from 'react';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ErrorsPagesCss from './css/ErrorsPagesCss';
import {Grid, Typography, withStyles} from '@material-ui/core';

class Error403 extends Component {
  render() {
    const {classes, ...props} = this.props;
    return (
      <Grid
        container
        alignItems='center'
        {...props}
      >
        <Grid item lg={4}>
          <ErrorOutline
            className={classes.errorIcon}
            color='primary'
          />
        </Grid>
        <Grid item lg={8}>
          <Typography
            variant='h1'
            className={classes.title}
          >
            403 Error
          </Typography>
          <Typography
            color='primary'
            variant='h2'
            className={classes.subtitle}
          >
            Accès non autorisé
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

Error403 = withStyles(ErrorsPagesCss)(Error403);

export default Error403
