import {assetsForCss} from '../../../tools/Tools';

export default theme => ({
  loginContainer: {
    background: 'url(' + assetsForCss('/img/default-background.jpg') + ')',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100%',
  },
  paper: {
    padding: '2em',
  },
  title: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  avatar: {
    backgroundColor: '#26323800',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    width: '100%',
  },
  avatarImg: {
    width: '200px',
  },
  textField: {
    width: '100%',
  },
  buttonsLign: {
    marginTop: '3em',
  },
  button: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
})
