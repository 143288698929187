import {DialogContent, DialogTitle, Fab, Fade, Grid, IconButton, withStyles} from '@material-ui/core';
import {Add, Delete, Done, Edit, Warning} from '@material-ui/icons';
import {Cell, DataTable} from '@oniti/datatable-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from "react-redux";
import {
  collectionActions,
  loadCollectionAttribute,
  removeReconciliations,
  setReconciliation,
} from "../../../../reducers/collectionsReducer";
import {hasRights} from '../../Tools/Tools';
import Modal from '@oniti/oniti-modal';
import CreateUpdate from './CreateUpdate';
import CollectionCrudCss from './css/CollectionCrudCss';
import AppConfig from '../../../config';

class CollectionCrud extends Component {

  constructor(props) {
    super(props);
    if (!props.collectionsStore[props.collectionName]) throw new Error('Collection inconnue')

    this.state = {
      list: [],
      openModal: (!!props.modal) ? props.modal.open : false,
      openDeleteModal: false,
      uuidSelected: null,
      detail: null,
      enableOffLineMode: AppConfig.enableOffLineMode,
      localStorageDataKey: AppConfig.prefixLocalStorage + '_' + props.collectionName + '_data',
      localStorageTimeKey: AppConfig.prefixLocalStorage + '_' + props.collectionName + '_time',
      online: props.online,
      showModalReconciliation: false,
    };

  }

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    // Met à jour l'état de la modale de création/modification
    if (nextProps.hasOwnProperty('modal') && !!nextProps.modal
      && (nextProps.modal.open !== prevState.openModal)) {
      return {openModal: nextProps.modal.open}
    }

    // Met à jour l'état de la collection
    const {collectionsStore, collectionName, datas, online, dispatch} = nextProps
    let propsList = datas ? datas : collectionsStore[collectionName].list,
      localstoragedata = localStorage.getItem(prevState.localStorageDataKey) ? localStorage.getItem(prevState.localStorageDataKey) : null,
      cached = false
    //Récupère les données du storage si on est déconnecté
    if (!online && localstoragedata) {
      propsList = JSON.parse(localstoragedata)
      cached = true
      // et on reset la collection pour la recharger une fois en ligne
      if (collectionsStore[collectionName].list || collectionsStore[collectionName].detail) collectionActions(dispatch, collectionName, 'RESET_ALL')
    }
    // Quand on repasse en ligne on recharge les données du back
    let needLoadDatas = nextProps.hasOwnProperty('loadDatas') ? nextProps.loadDatas : true
    if ((!prevState.online || !collectionsStore[collectionName].list) && online && needLoadDatas) {
      loadCollectionAttribute(dispatch, 'list', collectionName, collectionsStore[collectionName])
    }
    if (propsList !== prevState.list) {
      //Si on doit gérer le mode off line on garde les données dans le storage.
      if (prevState.enableOffLineMode && propsList && !cached) {
        localStorage.setItem(prevState.localStorageDataKey, JSON.stringify(propsList));
        let now = new Date(Date.now())
        localStorage.setItem(prevState.localStorageTimeKey, now.toLocaleDateString("fr-FR") + ' ' + now.toLocaleTimeString("fr-FR"));
      }
      return {
        list: propsList,
        online: online,
      }
    }
    return null
  }

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    const {
      collectionsStore, collectionName, dispatch, rights, user, loadDatas,
    } = this.props

    const {online} = this.state
    let readRight = true
    if (!!rights && !!rights.read) {
      readRight = hasRights(rights.read, user)
    }
    let needLoadDatas = this.props.hasOwnProperty('loadDatas') ? loadDatas : true
    if (needLoadDatas && readRight && !Array.isArray(this.props.datas) && online) {
      loadCollectionAttribute(
        dispatch, 'list', collectionName, collectionsStore[collectionName], null,
      )
    }
  }

  /**
   * Handler sur edit
   * @param  {[type]} detail [description]
   * @return {[type]}      [description]
   */
  onClickEditHandler(detail) {
    this.setState({
      openModal: true,
      uuidSelected: detail.uuid,
    });
    if (this.props.onModalOpen) {
      this.props.onModalOpen(detail);
    }
  }

  /**
   * Fermeture de la modal de delete
   * @return {[type]} [description]
   */
  onCloseDeleteModalHandler() {
    this.setState({
      openDeleteModal: false,
      detail: null,
    })
  }

  /**
   * Validation de l'action delete
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {dispatch, collectionName, actionsCallback, online} = this.props;

    if (online) {
      collectionActions(dispatch, collectionName, 'DELETE', this.state.detail, () => {
        if (actionsCallback) actionsCallback('delete', this.state.detail)
      });
    } else if (AppConfig.enableOffLineMode) {
      setReconciliation(dispatch, collectionName, 'DELETE', this.state.detail)
    }

    this.setState({
      openDeleteModal: false,
      user: null,
    })
  }

  /**
   * Clic sur le bouton Delete
   * @param  {[type]} detail [description]
   * @return {[type]}        [description]
   */
  onClickDeleteHandler(detail) {
    this.setState({
      openDeleteModal: true,
      detail,
    })
  }

  /**
   * Handler pour ajouter un detail de la collection
   */
  addBtnCallBack() {
    this.setState({
      openModal: true,
      uuidSelected: null,
    });
    if (this.props.onModalOpen) {
      this.props.onModalOpen({});
    }
  }

  /**
   * Handler sur la fermeture de la modal
   * @return {[type]} [description]
   */
  onCloseModalHandler() {
    const {actionsCallback, collectionName, dispatch} = this.props;
    this.setState({
      openModal: false,
      uuidSelected: null,
    });
    collectionActions(dispatch, collectionName, 'RESET_ERRORS')
    if (actionsCallback) actionsCallback('close', this.state.detail)
  }

  /**
   * Rendu de la cellule des actions
   * @param  {[type]} object [description]
   * @return {[type]}       [description]
   */
  formatActions(object) {
    let {
      classes,
      showBtnEdit,
      showBtnDelete,
      rights,
      user,
      additionnalControllers,
      showBtnDeleteCallBack,
      showBtnEditCallBack,
      canLoadDetailFromCache,
      online,
      enableOffLineModification,
    } = this.props;

    if (!!rights && !!rights.edit && showBtnEdit) {
      showBtnEdit = hasRights(rights.edit, user)
    }
    if (showBtnDelete && showBtnDeleteCallBack) showBtnDelete = showBtnDeleteCallBack(object)

    if (!!rights && !!rights.delete && showBtnDelete) {
      showBtnDelete = hasRights(rights.delete, user)
    }

    if (showBtnEdit && showBtnEditCallBack) showBtnEdit = showBtnEditCallBack(object)

    additionnalControllers = additionnalControllers ? additionnalControllers : [];

    let btnEdit = (
        <IconButton
          aria-label="Edit"
          key="edit"
          color="primary"
          onClick={this.onClickEditHandler.bind(this, object)}
          className={classes.button}
          title="Modifier"
          disabled={!(online || (canLoadDetailFromCache && enableOffLineModification))}
        >
          <Edit/>
        </IconButton>
      ),
      btnDelete = (
        <IconButton
          aria-label="Delete"
          onClick={this.onClickDeleteHandler.bind(this, object)}
          className={classes.button}
          key="delete"
          title="Supprimer"
          disabled={!(online || enableOffLineModification)}
        >
          <Delete/>
        </IconButton>
      );

    let result = [];

    additionnalControllers.forEach(f => result.push(f(object)));

    return [
      showBtnDelete ? btnDelete : null,
      showBtnEdit ? btnEdit : null,
    ].concat(result);
  }

  /**
   * Bouton d'ajout d'un utilisateur
   * @return {[type]} [description]
   */
  getBtnAdd() {
    let {classes, rights, user, enableOffLineModification, online} = this.props;
    let showBtn = true
    if (!!rights && !!rights.create) {
      showBtn = hasRights(rights.create, user)
    }

    if (!showBtn) return false
    return (
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        onClick={this.addBtnCallBack.bind(this)}
        className={classes.addButton}
        title="Ajouter"
        disabled={!(online || enableOffLineModification)}
      >
        <Add style={{fontSize: 32}}/>
      </Fab>
    );
  }

  /**
   * Retourne les Cells du datatable
   * @return {[type]} [description]
   */
  getCells() {
    const {
      showBtnEdit, showBtnDelete, additionnalControllers, cellsConfig, classes,
    } = this.props;
    let cells = cellsConfig.map((conf, index) => <Cell key={index} {...conf} />);

    if (showBtnEdit || showBtnDelete || additionnalControllers) {
      cells.push(
        <Cell
          key="crud"
          format={this.formatActions.bind(this)}
          sortable={false}
          className={classes.crudTd}
        />,
      );
    }

    return cells;
  }

  //Save state on unmount Datatable
  saveDatatableState(state) {
    const {persistDatatableOptions} = this.props;
    if (!!persistDatatableOptions) {
      localStorage.setItem(this.getStorageKeyDatatable(), JSON.stringify(state))
    }
  }

  getStorageKeyDatatable() {
    const {persistDatatableOptions, collectionName} = this.props

    return 'datatable_' + (persistDatatableOptions.id ? persistDatatableOptions.id : collectionName)
  }

  //Restore state for datatable
  getInitValue() {
    const {persistDatatableOptions} = this.props;
    let initValue = null

    if (!!persistDatatableOptions) {
      let storagekey = this.getStorageKeyDatatable()
      if (localStorage[storagekey]) {
        initValue = JSON.parse(localStorage[storagekey])
        let now = new Date().getTime(),
          expirationTime = !!persistDatatableOptions.expirationMinutes ?
            (initValue.timestamp + (persistDatatableOptions.expirationMinutes * 60000)) :
            null
        //Vérification de l'expiration
        if (!!persistDatatableOptions.expirationMinutes && now >= expirationTime) {
          localStorage.removeItem(storagekey)
          initValue = null
        }
      }
    }

    return initValue
  }

  /**
   * Formatte la cellule contenant le type d'action
   * @param {*} reconciliation
   */
  formatActionsReconciliation(reconciliation) {
    let mapping = {
      'UPDATE': 'Mise à jour',
      'CREATE': 'Création',
      'DELETE': 'Suppression',
    }
    return mapping[reconciliation.action]
  }

  /**
   * Format générique pour les reconciliations
   * @param {*} conf
   * @param {*} reconciliation
   */
  formatReconciliation(conf, reconciliation) {
    if (conf.datakey && !conf.format) {
      let value = reconciliation.data
      conf.datakey.split('.').forEach(key => {
        value = value[key]
      })
      return value
    } else if (conf.format) {
      return conf.format(reconciliation.data)
    } else return null
  }

  formatActionsReconciliationBtn(reconciliation) {
    const {classes, dispatch, online} = this.props
    let btnDone = (
        <IconButton
          aria-label="valid"
          key="valid"
          color="primary"
          onClick={() => {
            collectionActions(dispatch, reconciliation.collectionName, reconciliation.action, reconciliation.data, () => {
              removeReconciliations([reconciliation.id])
            })
          }}
          className={classes.button}
          title="Valider"
          disabled={!online}
        >
          <Done/>
        </IconButton>
      ),
      btnDelete = (
        <IconButton
          aria-label="Delete"
          onClick={() => {
            removeReconciliations([reconciliation.id])
          }}
          className={classes.button}
          key="delete"
          title="Supprimer"
        >
          <Delete/>
        </IconButton>
      );
    return [
      btnDelete,
      btnDone,
    ]
  }

  /**
   * Retourne les Cells pour le datatable
   * @param {*} reconciliationFieldsToCompare
   */
  getCellsReconciliation(reconciliationFieldsToCompare) {
    let cells = [
      <Cell
        title="Action"
        key={'action'}
        format={this.formatActionsReconciliation.bind(this)}
        sortable={false}
      />,
    ];
    cells = cells.concat(reconciliationFieldsToCompare.map((conf, index) => {
      let newConf = {...conf}
      newConf.format = this.formatReconciliation.bind(this, conf)
      return <Cell key={index} {...newConf} />
    }))

    cells.push(
      <Cell
        key="crud"
        format={this.formatActionsReconciliationBtn.bind(this)}
        sortable={false}
        className={this.props.classes.crudTd}
      />,
    )

    return cells
  }

  /**
   * Modal de confirmation de suppression
   */
  getModalReconciliation() {
    const {
      showModalReconciliation,
    } = this.state;

    const {reconciliationFieldsToCompare, waitingReconciliations, collectionName, enableOffLineModification} = this.props

    if (AppConfig.enableOffLineMode && !reconciliationFieldsToCompare && enableOffLineModification) throw new Error('Configuration reconciliationFieldsToCompare Obligatoire.')

    if (showModalReconciliation) {
      return (
        <Modal
          openModal={this.state.showModalReconciliation}
          onCloseHandler={() => { this.setState({showModalReconciliation: false})}}
          fullWidth={true}
          maxWidth='lg'
          actionOk='NONE'
        >
          <DialogTitle key="title" id="alert-dialog-slide-title">
            <Warning/> Modifications en attente
          </DialogTitle>
          <DialogContent>
            <DataTable
              datas={waitingReconciliations ? waitingReconciliations.filter(r => r.collectionName === collectionName) : []}
              showPagination={false}
              showSearch={true}
              cancelUpdateCheck={true}
            >
              {this.getCellsReconciliation(reconciliationFieldsToCompare)}
            </DataTable>
          </DialogContent>
        </Modal>
      )
    } else {
      return null
    }
  }

  /**
   * Modal de confirmation de suppression
   */
  getModalDelete() {
    const {
      showBtnDelete,
      deleteModalTitle,
      deleteModalContent,
      modal,
      classes,
    } = this.props;

    if (!modal && showBtnDelete) {
      return (
        <Modal
          openModal={this.state.openDeleteModal}
          onCloseHandler={this.onCloseDeleteModalHandler.bind(this)}
          onSubmitHandler={this.onSubmitHandler.bind(this)}
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogTitle className={classes.modalTitle} key="title" id="alert-dialog-slide-title">
            {deleteModalTitle(this.state.detail)}
          </DialogTitle>
          <DialogContent>
            {deleteModalContent(this.state.detail)}
          </DialogContent>
        </Modal>
      )
    } else {
      return null
    }
  }

  /**
   * Modal de mise a jour ou création
   */
  getCreateUpdate() {
    const {
      collectionName,
      showBtnAdd,
      showBtnEdit,
      createUpdateModalTitle,
      createUpdateModalContent,
      createUpdateModalAction,
      extraDatasForm,
      actionsCallback,
      modalMaxWidth,
      disabledEnterModal,
      defaultValues,
      detailStateCallback,
      canLoadDetailFromCache,
    } = this.props;

    if (showBtnAdd || showBtnEdit) {
      return <CreateUpdate
        disabledEnterModal={disabledEnterModal}
        openModal={this.state.openModal}
        uuidSelected={this.state.uuidSelected}
        onCloseHandler={this.onCloseModalHandler.bind(this)}
        collectionName={collectionName}
        createUpdateModalTitle={createUpdateModalTitle}
        createUpdateModalContent={createUpdateModalContent}
        createUpdateModalAction={createUpdateModalAction}
        extraDatasForm={extraDatasForm}
        actionsCallback={actionsCallback}
        modalMaxWidth={modalMaxWidth}
        defaultValues={defaultValues}
        detailStateCallback={detailStateCallback}
        canLoadDetailFromCache={canLoadDetailFromCache}
        localStorageDataKey={this.state.localStorageDataKey}
        fullScreen={this.props.fullScreenModal}
      />
    } else {
      return null
    }
  }

  /**
   * Partie Datatable
   */
  getDatatable() {
    const {
      datatableConfig,
      modal,
      cancelUpdateCheck,
      dataTableExtraNodes,
      showBtnAdd,
    } = this.props;

    let extraNodes = null;
    if (!!dataTableExtraNodes) {
      if (extraNodes === null) extraNodes = dataTableExtraNodes;
      else extraNodes = extraNodes.concat(dataTableExtraNodes)
    }
    if (showBtnAdd) {
      extraNodes = [{
        element: this.getBtnAdd(),
        position: 'top-right',
      }]
    }

    if (!modal || modal.only !== true) {
      return (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <DataTable
              datas={this.state.list ? this.state.list : []}
              {...datatableConfig}
              extraNodes={extraNodes}
              cancelUpdateCheck={cancelUpdateCheck}
              getStateOnUnmount={this.saveDatatableState.bind(this)}
              initialValues={this.getInitValue()}
            >
              {this.getCells()}
            </DataTable>
          </Grid>
        </Grid>
      )
    } else {
      return null
    }
  }

  /**
   * Affiche l'information de la fraîcheur des informations
   */
  getOfflineInfo() {
    const {online, classes} = this.props,
      {localStorageTimeKey, enableOffLineMode} = this.state,
      date = localStorage.getItem(localStorageTimeKey)
    if (!enableOffLineMode || online || !date) return null
    else return (
      <div className={classes.offLineDataTime}>
        Données datant du {date}
      </div>
    )
  }

  /**
   * Affiche l'information sur les modification en attente
   */
  getReconciliationsInformation() {
    let {waitingReconciliations, collectionName, classes} = this.props,
      collectionWaitingReconciliations = waitingReconciliations ? waitingReconciliations.filter(r => r.collectionName === collectionName) : []

    if (AppConfig.enableOffLineMode && collectionWaitingReconciliations.length > 0) {
      return (
        <div className={classes.offLineReconciliations}>
          <IconButton
            aria-label="Modification en cours"
            onClick={() => {this.setState({showModalReconciliation: true})}}
            key="reconciliation"
            title="Modification en cours"
            color='inherit'
          >
            <Warning/>
          </IconButton>
          <span>Modifications faites Hors ligne en attente {collectionWaitingReconciliations.length}.</span>
        </div>
      )
    } else {
      return null
    }

  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {
      classes,
      rights,
      user,
    } = this.props;

    let showDataTable = true
    if (!!rights && !!rights.read) {
      showDataTable = hasRights(rights.read, user)
    }
    if (!showDataTable) return null

    return (
      <Grid container>
        <Fade in={true}>
          <div className={classes.root}>
            {this.getReconciliationsInformation()}
            {this.getOfflineInfo()}
            {this.getDatatable()}
            {this.getModalDelete()}
            {this.getCreateUpdate()}
            {this.getModalReconciliation()}
          </div>
        </Fade>
      </Grid>
    );
  }
}

CollectionCrud = withStyles(CollectionCrudCss)(CollectionCrud);

CollectionCrud = connect((store) => {
  return {
    collectionsStore: store.collections,
    user: store.auth.user,
    online: store.app.online,
    waitingReconciliations: store.app.waitingReconciliations,
  }
})(CollectionCrud);

CollectionCrud.propTypes = {
  // store qui est écouté, URL vers le back, ...
  collectionName: PropTypes.string.isRequired,

  // config du DataTable et ses cellules,
  // cf https://www.npmjs.com/package/@oniti/datatable-material
  cellsConfig: PropTypes.array.isRequired,
  datatableConfig: PropTypes.object.isRequired,

  // optionnel : en passant loadDatas à false, on injecte les données
  // à la main par la prop "datas" -> Pour que le loadDatas à false 
  // soit pris en compte, il faut déclarer "datas" avec un tableau vide.
  // Les insert/update/delete continuent à fonctionner
  // comme d'hab (voir Cap Eco, par exemple)
  // L'usage prévu est le filtrage des données affichées.
  datas: PropTypes.array,
  loadDatas: PropTypes.bool,

  // affichage ou non des boutons d'action
  showBtnEdit: PropTypes.bool,
  showBtnAdd: PropTypes.bool,
  showBtnDelete: PropTypes.bool,

  // callback pour un affichage ou non du bouton d'action à la ligne près
  // (reçoit l'objet de la ligne et renvoie un booléen)
  showBtnDeleteCallBack: PropTypes.func,
  showBtnEditCallBack: PropTypes.func,

  // gère l'affichage des boutons en fonction des droits de l'utilisateur
  // voir également le Controller associé (__construct)
  // rights={{
  //   create: 'admin-c-affaires',
  //   edit: 'admin-u-affaires',
  //   delete: 'admin-d-affaires'
  // }}
  rights: PropTypes.object,

  // callbacks des titres et contenus
  deleteModalTitle: PropTypes.func,
  deleteModalContent: PropTypes.func,
  createUpdateModalTitle: PropTypes.func,
  createUpdateModalContent: PropTypes.func,
  // titre du bouton de validation de la modale
  createUpdateModalAction: PropTypes.string,

  // données envoyées en plus lors d'un insert/update
  // {facture_uuid: this.state.facture.uuid}
  extraDatasForm: PropTypes.object,

  // ajoute des boutons supplémentaires sur chaque ligne
  // [ this.getDuplicateButton.bind(this), this.getDetailButton.bind(this)]
  // la fonction reçoit l'objet de la ligne cliquée et doit renvoyer
  // un IconButton (ou truc du genre)
  additionnalControllers: PropTypes.array,

  // permet d'accrocher une fonction à une action (create/update/delete)
  // la fonction reçoit l'action et l'objet (cf Cap Eco)
  actionsCallback: PropTypes.func,

  // permet d'ajouter des éléments autour du CollectionCrud
  // dataTableExtraNodes={[
  //   {
  //     element: this.getCrudTitle(),
  //     position: 'top-left',
  //   }
  // ]}
  dataTableExtraNodes: PropTypes.arrayOf(PropTypes.object),

  // permet d'indiquer la taille souhaitée pour le Dialog :
  // 'xs', 'sm', 'md', 'lg', 'xl', false
  modalMaxWidth: PropTypes.string,

  // modal={{only: true, open: this.state.xxx}}
  // permet de n'avoir que la gestion de la modale de création et
  // pas la grille.
  modal: PropTypes.object,

  // désactive la touche "Enter" pour la validation de la modale
  disabledEnterModal: PropTypes.bool,

  // désactive le filtrage par défaut des propriétés (qui permet d'éviter
  // de faire trop de render). Exemple : ajout de checkboxes dans la grille
  cancelUpdateCheck: PropTypes.bool,

  // detailStateCallback : permet d'informer notre parent des changements
  // de state des champs (ex: checkbox qui change le disabled d'un input à coté)
  // (et permet également de modifier le state en modifiant l'argument !)
  // ex : handleDetailStateUpdate(newState) {} (cf ILMG)
  detailStateCallback: PropTypes.func,

  // callback appelé à l'ouverture de la modale create/update, reçoit l'objet
  // en arguement
  onModalOpen: PropTypes.func,

  // permet d'alimenter la modale de création avec des valeurs par défaut
  defaultValues: PropTypes.object,

  // fait persister l'état du composant (recherche, tris, numéro de page,
  // taille de la page, ...). Objet vide {} accepté.
  // {
  //  id: xxx (optionnel, prend le nom de la collection par défaut)
  //  expirationMinutes: xxx (optionnel, n'expire pas par défaut)
  // }
  persistDatatableOptions: PropTypes.object,

  /**
   * Définie si on peut récupérer les données depuis la liste gardé dans le localstorage
   */
  canLoadDetailFromCache: PropTypes.bool,
  /**
   * Liste des champs a comparer lors de la réconsilliation (au niveau de l'affichage)
   */
  reconciliationFieldsToCompare: PropTypes.arrayOf(PropTypes.object),

  /**
   * Authorise les modifications en mode hors ligne
   */
  enableOffLineModification: PropTypes.bool,
  fullScreenModal: PropTypes.bool,
};

CollectionCrud.defaultProps = {
  canLoadDetailFromCache: false,
  enableOffLineModification: false,
};

export default CollectionCrud
