export default theme => ({
  root: {
    maxWidth: '100%'
  },
  dateInput: {
    display: 'inline-block',
    width: 'calc(33% - 8px)',
    marginLeft: '4px',
    marginRight: '4px',
  },
  dateDisabled: {
    opacity: 0.5,
  },
})
