export default theme => ({
  crudTitle: {
    float: 'left',
    marginBottom: 0,
    padding: '8px 0',
    color: theme.palette.primary.main,
  },
  divider: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  sectionTitle: {
    marginBottom: '1em',
    color: theme.palette.primary.main,
  },
  currency: {
    textAlign: 'right',
  },
  details : {
    borderBottom: '1px dashed black',
    cursor: 'help',
  },
  missingNumDoss : {
    backgroundColor: '#f2dede',
  },
  waitingParent : {
    backgroundColor: '#e5eef3',
  },
  allDone : {
    backgroundColor: '#dff0d8',
  },
})
