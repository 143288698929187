import {axiosClient} from '../axios';
import {addNotification, axiosErrorHandler} from './notificationReducer';
import {themeComplement} from "../components/admin/AppAdmin/css/theme";

/**
 * ###########################################################################
 * ##########################  FUNCTIONS #####################################
 * ###########################################################################
 */
/**
 * Déconnection
 * @param  {[type]} dispatch [description]
 * @return {[type]}          [description]
 */
export function logout(dispatch) {
  dispatch({type: 'LOGOUT'});
  axiosClient.post('/auth/logout')
    .catch(error => axiosErrorHandler(dispatch, error))
}

/**
 * Login
 * @param  {[type]} dispatch [description]
 * @param  {[type]} data     [description]
 * @return {[type]}          [description]
 */
export function login(dispatch, data) {
  axiosClient.post('/auth/login', data)
    .then(response => {
      getUser(dispatch)
    })
    .catch(error => axiosErrorHandler(dispatch, error))
}

/**
 * Récupère l'utilisateur courrant
 * @param  {[type]} dispatch [description]
 * @return {[type]}          [description]
 */
export function getUser(dispatch) {
  dispatch({
    type: 'LOGIN_PENDING',
  });
  axiosClient.get('/auth/me')
    .then(response => {
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: response.data,
      })
      setAuthExpiration(dispatch)
    })
    .catch(error => {
      dispatch({
        type: 'LOGIN_ERROR',
      })
    })
}

export function getExpirationFromCookie() {
  let cookies = document.cookie.split(';').map(c => {
    let data = c.split('=')
    return {
      key: data[0],
      value: data[1],
    }
  })
  let expirationCookie = cookies.find(c => c.key === 'expiration')
  return expirationCookie ? decodeURIComponent(expirationCookie.value.replace(/\+/g, " ")) : null
}

export function setAuthExpiration(dispatch) {
  dispatch({
    type: 'AUTH_SET_EXPIRATION',
    payload: getExpirationFromCookie(),
  })
}

/**
 * Fonction pour envoyé l'email de mot de passe perdu
 * @param dispatch
 * @param data
 * @param cb
 */
export function passwordLost(dispatch, data, cb) {
  axiosClient.post('/auth/password/lost', data)
    .then(response => {
      addNotification(dispatch, {
        message: response.data.success,
        bgColor: themeComplement.palette.notifications.success.color,
      });
      dispatch({
        type: 'AUTH_ERRORS_RESET',
      });
      cb()
    })
    .catch(error => {
      axiosErrorHandler(dispatch, error);
      dispatch({
        type: 'PASSWORD_LOST_ERROR',
        payload: error.response.data.errors,
      })
    })
}

/**
 * Fonction pour changer de mot de passe
 * @param dispatch
 * @param data
 * @param cb
 */
export function passwordReset(dispatch, data, cb) {
  axiosClient.post('/auth/password/reset', data)
    .then(response => {
      addNotification(dispatch, {
        message: response.data.success,
        bgColor: themeComplement.palette.notifications.success.color,
      });
      dispatch({
        type: 'AUTH_ERRORS_RESET',
      });
      getUser(dispatch);
      cb()
    })
    .catch(error => {
      axiosErrorHandler(dispatch, error);
      dispatch({
        type: 'PASSWORD_RESET_ERROR',
        payload: error.response.data.errors,
      })
    })
}

/**
 * Fonction pour changer de mot de passe
 * @param dispatch
 * @param data
 * @param cb
 */
export function passwordChange(dispatch, data, cb) {
  axiosClient.post('/auth/password/change', data)
    .then(response => {
      addNotification(dispatch, {
        message: response.data.success,
        bgColor: themeComplement.palette.notifications.success.color,
      });
      dispatch({
        type: 'AUTH_ERRORS_RESET',
      });
      cb()
    })
    .catch(error => {
      axiosErrorHandler(dispatch, error);
      dispatch({
        type: 'PASSWORD_RESET_ERROR',
        payload: error.response.data.errors,
      })
    })
}

/**
 * Redirige vers le login
 * @param  {[type]} dispatch [description]
 * @return {[type]}          [description]
 */
export function forceRedirectLogin(dispatch) {
  dispatch({
    type: 'FORCE_REDIRECT_LOGIN',
  })
}

/**
 * Informe que la redirection a été faite
 * @param  {[type]} dispatch [description]
 * @return {[type]}          [description]
 */
export function redirectLoginDone(dispatch) {
  dispatch({
    type: 'REDIRECT_LOGIN_DONE',
  })
}

/**
 * ###########################################################################
 * ##########################  REDUCER   #####################################
 * ###########################################################################
 */

let initialState = {
  fetching: false,
  user: null,
  errors: null,
  goToLogin: false,
  expiration: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH_SET_EXPIRATION': {
      return {...state, expiration: action.payload}
    }
    case 'FORCE_REDIRECT_LOGIN': {
      return {...initialState, goToLogin: true};
    }
    case 'REDIRECT_LOGIN_DONE': {
      return {...state, goToLogin: false};
    }
    case 'AUTH_ERRORS_RESET': {
      return {...state, errors: null};
    }
    case 'PASSWORD_LOST_ERROR':
    case 'PASSWORD_RESET_ERROR': {
      return {...state, errors: action.payload};
    }
    case 'LOGIN_ERROR': {
      return {...state, fetching: false, user: null}
    }
    case 'LOGIN_PENDING': {
      return {...state, fetching: true, user: null, errors: null}
    }
    case 'LOGIN_SUCCESS': {
      return {...state, user: action.payload, fetching: false, errors: null}
    }
    case 'LOGOUT': {
      return {...initialState}
    }
    default:
      return state
  }
}
