import {Grid, Typography, withStyles} from '@material-ui/core'
import {Visibility} from '@material-ui/icons'

import React, {Component} from 'react'
import {connect} from 'react-redux'
import CollectionCrud from '../../common/Components/CollectionCrud'
import SignaturesCss from './css/SignaturesCss'
import { collectionActions, updateFormulaireMark } from '../../../reducers/collectionsReducer'
import CheckboxInput from "../../common/Components/CheckboxInput"

class Signatures extends Component {
  state = {
    selectedSejour: null,
  }

  componentDidMount() {
    let {dispatch} = this.props;

    collectionActions(
      dispatch,
      'signatures',
      'INDEX',
    )
  }

  getDateTime(string) {
    if (!string) {
      return ''
    }
    let date = new Date(string)
    let options = {day: 'numeric', month: 'numeric', year: 'numeric'}
    return date.toLocaleTimeString('fr-FR', options)
  }

  getDate(string) {
    if (!string) {
      return ''
    }
    let date = new Date(string)
    let options = {day: 'numeric', month: 'numeric', year: 'numeric'}
    return date.toLocaleDateString('fr-FR', options)
  }

  signatureLink(line) {
    // en dev, remplacer le :3000 de l'url par :8000
    let p = line.uuid.split('_')
    return '/souhaits/formulaire_' + p[1]
  }

  markClickHandler(line) {
    updateFormulaireMark(
      this.props.dispatch, line.uuid, {
        is_marked: !line.is_marked,
      }
    )
  }

  getActionsButtons(line) {
    const {classes} = this.props

    var btns = []

    btns.push(
      <a
        key="link"
        title="Voir le formulaire signé"
        href={this.signatureLink(line)}
        className={classes.signatureLink}
        target='_blank'
        rel="noopener noreferrer"
      >
        <Visibility/>
      </a>
    )
    return btns
  }

  getMarkButton(line) {
    return (
      <CheckboxInput
          id='is_marked'
          value={line.is_marked}
          name='is_marked'
          onChangeHandler={this.markClickHandler.bind(this, line)}
      />
    )
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container spacing={2} className={classes.container}>
        <Typography
          variant="button"
          gutterBottom
          className={classes.sectionTitle}
        >
          Derniers formulaires signés
        </Typography>

        <CollectionCrud
          collectionName={'signatures'}
          loadDatas={false}
          showBtnEdit={false}
          showBtnAdd={false}
          showBtnDelete={false}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            rowsPerPageOptions: [20, 50, 100],
          }}
          cellsConfig={[
            {
              datakey: 'signature_date',
              title: 'Date signature',
              sortable: true,
              searchable: true,
              format: (obj, key) => {
                return this.getDateTime(obj[key])
              },
            },
            {
              datakey: 'enfant_nom',
              title: 'Nom enfant',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'enfant_prenom',
              title: 'Prénom',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'sejour_infos',
              title: 'Séjour',
              sortable: true,
              searchable: true,
              format: (obj, key) => {
                return obj.sejour.libelle + ' (' + obj.sejour.identifiant + ')'
              },
            },
            {
              title: 'Marqué',
              format: this.getMarkButton.bind(this),
              sortable: false,
            },
            {
              format: this.getActionsButtons.bind(this),
              sortable: false,
            },
          ]}
        />
      </Grid>
    );
  }
}

Signatures = withStyles(SignaturesCss)(Signatures)

Signatures = connect((store) => {
  return {
    signaturesStore: store.collections.signatures,
  }
})(Signatures);

export default Signatures
