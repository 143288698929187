import {Avatar, Button, Grid, TextField, withStyles} from '@material-ui/core';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {login} from '../../../reducers/authReducer';
import AuthCss from './css/AuthCss';
import Logo from '../../../assets/img/logo.png';

class Login extends Component {
  state = {
    email: '',
    password: '',
    logged: false,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.logged && !!nextProps.auth.user) {
      let previousPathRoute = nextProps.pathRouteHistory.find(path => !path.startsWith('/admin/auth'));
      nextProps.history.push(previousPathRoute ? previousPathRoute : '/admin');
      return {
        ...prevState,
        logged: true,
      };
    } else if (!nextProps.auth.user && prevState.logged) {
      return {
        ...prevState,
        logged: false,
      };
    }

    return null;
  }

  /**
   * Handler de changement de valeur pour les formulaires
   * @param {*} event
   */
  handleChange(event) {
    let {value, name} = event.target;
    this.setState({
      [name]: value,
    });
  };

  /**
   * Soumission du formulaire
   * @return {[type]} [description]
   */
  onClickSubmit() {
    login(this.props.dispatch, this.state)
  }

  /**
   * Handle du mot de passe perdu
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onClickPasswordLost(e) {
    e.preventDefault();
    this.props.history.push("/admin/auth/password/lost");
  }

  /**
   * Pour valider le formulaire avec Enter
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onKeyPress(e) {
    if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') { // Différents modes de détection pour prendre en charge l'ensemble des navigateurs
      this.onClickSubmit()
    }
  }

  /**
   * Fonction de rendu final
   * @return {[type]} [description]
   */
  render() {
    const {classes} = this.props;
    return (
      <>
        <Grid item>
          <Avatar className={classes.avatar}>
            <img src={Logo} alt='Logo' className={classes.avatarImg}/>
          </Avatar>
        </Grid>
        <Grid item>
          <TextField
            name="email"
            label="Email"
            className={classes.textField}
            value={this.state.email}
            onChange={this.handleChange.bind(this)}
            onKeyPress={this.onKeyPress.bind(this)}
            helperText=""
            error={false}
            type={"email"}
            autoComplete={"off"}
            margin="normal"
          />
        </Grid>
        <Grid item>
          <TextField
            name="password"
            label="Mot de passe"
            className={classes.textField}
            value={this.state.password}
            onChange={this.handleChange.bind(this)}
            onKeyPress={this.onKeyPress.bind(this)}
            type={"password"}
            autoComplete={"off"}
            margin="normal"
          />
        </Grid>
        <Grid item className={classes.buttonsLign}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={this.onClickSubmit.bind(this)}
            className={classes.button}
          >
            Valider
          </Button>
          <Button
            color="primary"
            onClick={this.onClickPasswordLost.bind(this)}
            className={classes.button}
          >
            Mot de passe oublié ?
          </Button>
        </Grid>
      </>
    );
  }
}

Login = connect((store) => {
  return {
    auth: store.auth,
    pathRouteHistory: store.app.pathRoutesHistory,
    currentRouteConfig: store.app.currentRouteConfig,
  }
})(Login);

Login = withStyles(AuthCss)(Login);
Login = withRouter(Login);

export default Login;
