import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Grid, TextField, Typography, withStyles} from '@material-ui/core';
import {passwordReset} from '../../../reducers/authReducer';
import {getErrorsMessage} from '../../tools/Tools';
import {addNotification} from '../../../reducers/notificationReducer';
import AuthCss from './css/AuthCss';
import RouterConfig from '../../../router/config';

class PasswordReset extends Component {
  state = {
    new_password: '',
    password_confirm: '',
    token: null,
    errors: null,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.errors && nextProps.auth.errors.token) {
      addNotification(nextProps.dispatch, {
        message: nextProps.auth.errors.token[0],
        bgColor: "#dd4b39",
      })
    }

    return null;
  }

  componentDidMount() {
    this.setState({token: this.props.match.params.token})
  }

  /**
   * Soumission du formulaire
   * @return {[type]} [description]
   */
  onClickSubmit() {
    passwordReset(this.props.dispatch, this.state, () => {
      this.props.history.push('/admin/')
    })
  }

  /**
   * Click sur le btn retour
   */
  OnClickBack() {
    this.props.history.push('/admin/auth')
  }

  /**
   * Handler de changement de valeur pour les formulaires
   * @param {*} event
   */
  handleChange(event) {
    let {value, name} = event.target;
    this.setState({
      [name]: value,
    });
  }

  /**
   * Pour valider le formulaire avec Entrer
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  onKeyPress(e) {
    if (e.key === 'Enter') {
      this.onClickSubmit()
    }
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {

    const {classes, location} = this.props;
    return (
      <>
        <Grid item>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {location[RouterConfig.pathname].includes('/create/') ? 'Création' : 'Réinitialisation'} de votre mot de
            passe
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            name="new_password"
            label="Nouveau mot de passe"
            value={this.state.password}
            className={classes.textField}
            onChange={this.handleChange.bind(this)}
            helperText={getErrorsMessage(this.props.auth.errors, 'new_password')}
            error={!!this.props.auth.errors && !!this.props.auth.errors.new_password}
            type={"password"}
            margin="normal"
            onKeyPress={this.onKeyPress.bind(this)}
          />
        </Grid>
        <Grid item>
          <TextField
            name="password_confirm"
            label="Confirmation"
            value={this.state.passwordConfirm}
            className={classes.textField}
            onChange={this.handleChange.bind(this)}
            helperText={getErrorsMessage(this.props.auth.errors, 'password_confirm')}
            error={!!this.props.auth.errors && !!this.props.auth.errors.password_confirm}
            type={"password"}
            margin="normal"
            onKeyPress={this.onKeyPress.bind(this)}
          />
        </Grid>
        <Grid item className={classes.buttonsLign}>
          <Button color={"primary"} onClick={this.OnClickBack.bind(this)}>Retour</Button>
          <Button
            variant="contained"
            color={"primary"}
            onClick={this.onClickSubmit.bind(this)}
            className={classes.validation}
          >
            Valider
          </Button>
        </Grid>
      </>
    );
  }
}

PasswordReset = connect((store) => {
  return {
    auth: store.auth,
    location: store.router.location,
  }
})(PasswordReset);

PasswordReset = withStyles(AuthCss)(PasswordReset);
PasswordReset = withRouter(PasswordReset);

export default PasswordReset
