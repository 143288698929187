import {Grid, withStyles} from '@material-ui/core'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getCampagne, updateCampagne} from '../../common/App/reducers/appReducer'
import DateInput from '../../common/Components/DateInput'
import {TextInput} from '@oniti/oniti-inputs';
import ParametresCss from './css/ParametresCss'
import moment from 'moment'

class CampagneReservations extends Component {
  state = {
    campagne: {
      uuid: null,
      date_ouverture: null,
      date_fermeture: null,
    }
  }

  componentDidMount() {
    getCampagne(this.props.dispatch, () => {
      this.setState({
        ...this.state,
        campagne: this.props.campagneStore
      })
    })
  }

  onChangeHandler(name, event) {
    const {campagne} = this.state;

    this.setState({
      campagne: {
        ...campagne,
        [name]: event.target.value
      }
    }, () => {
      updateCampagne(this.props.dispatch, !campagne.uuid ? 'create' : 'update', this.state.campagne)
    })
  }

  render() {
    const {classes, campagneStore} = this.props,
      {date_ouverture, date_cloture} = this.state.campagne
    const date_multiples = campagneStore.date_multiples
    return (
      <Grid item xs={6}>
        <div className={classes.dateInput}>
          <DateInput
            id="date_ouverture"
            label="Date d'ouverture"
            value={date_ouverture}
            margin="normal"
            collectionStore={campagneStore}
            name="date_ouverture"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
            pickertype="datetime"
            maxDate={date_cloture ? date_cloture : undefined}
            style={{marginTop: 0}}
          />
        </div>
        <div className={classes.dateInput}>
          <DateInput
            id="date_cloture"
            label="Date de clôture"
            value={date_cloture}
            margin="normal"
            collectionStore={campagneStore}
            name="date_cloture"
            onChangeHandler={this.onChangeHandler.bind(this)}
            fullWidth
            pickertype="datetime"
            minDate={date_ouverture ? date_ouverture : undefined}
            style={{marginTop: 0}}
          />
        </div>
        <div className={[classes.dateInput, classes.dateDisabled].join(' ')}>
          <TextInput
            readOnly
            id="date_multiples"
            label="Lancement inscr. multiples"
            value={date_multiples ? moment(date_multiples).format('DD/MM/YYYY HH:mm') : '-'}
            margin="normal"
            collectionStore={campagneStore}
            name="date_multiples"
            fullWidth
            style={{marginTop: 0}}
          />
        </div>
      </Grid>
    );
  }
}

CampagneReservations = withStyles(ParametresCss)(CampagneReservations);

CampagneReservations = connect((store) => {
  return {
    campagneStore: store.app.campagne,
  }
})(CampagneReservations);

export default CampagneReservations
