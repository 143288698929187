import {themeComplement} from '../../AppAdmin/css/theme'

export default theme => ({
  icon: {
    fontSize: '38px',
    color: themeComplement.palette.appBar.color,
  },
  listSubheader: {
    color: '#3d707d',
  },
  menuItem: {
    paddingLeft: '30px',
  },
})
