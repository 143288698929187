import {Component} from 'react';
import {connect} from 'react-redux';
import {getUser} from '../../../reducers/authReducer';
import {addPathRoute, updateOnlineStatus, updateReconciliations} from './reducers/appReducer'
import {removeReconciliations} from '../../../reducers/collectionsReducer'
import {withRouter} from 'react-router-dom';
import RouterConfig from '../../../router/config';
import AppConfig from '../../config';
import moment from 'moment'

class App extends Component {
  state = {
    previousRoutePath: null,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.previousRoutePath !== nextProps.location[RouterConfig.pathname]) {
      addPathRoute(nextProps.dispatch, nextProps.location[RouterConfig.pathname].replace(RouterConfig.prefixRoutes, ''));
      return {
        ...prevState,
        previousRoutePath: nextProps.location[RouterConfig.pathname],
      };
    }
    return null;
  }

  /**
   * Remplace componentWillMount
   * @return {[type]} [description]
   */
  componentDidMount() {
    getUser(this.props.dispatch);
    if (AppConfig.enableOffLineMode) {
      window.addEventListener("offline", () => {
        updateOnlineStatus(this.props.dispatch, false)
      }, false);
      window.addEventListener("online", () => {
        updateOnlineStatus(this.props.dispatch, true)
      }, false);

      window.addEventListener('reconciliationStorage', () => {
        let reconciliations = localStorage.getItem(AppConfig.reconciliationKey)
        if (reconciliations) {
          reconciliations = JSON.parse(reconciliations)
        }
        updateReconciliations(this.props.dispatch, reconciliations)
        this.unsetExpiredReconciliations(reconciliations)
      });

      //Récupère les reconciliations en attente lors du chargement initial de l'application
      let reconciliations = localStorage.getItem(AppConfig.reconciliationKey)
      if (reconciliations) {
        reconciliations = JSON.parse(reconciliations)
      }
      updateReconciliations(this.props.dispatch, reconciliations)
      this.unsetExpiredReconciliations(reconciliations)
    }
  }

  unsetExpiredReconciliations(reconciliations) {
    if (reconciliations) {
      let momentNow = moment(),
        reconciliationsExpired = reconciliations.filter(r => momentNow.isAfter(r.dateExpiration)).map(r => r.id)

      if (reconciliationsExpired.length > 0)
        removeReconciliations(reconciliationsExpired)
    }
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    return this.props.children
  }
}

App = connect((store) => {
  return {
    location: store.router.location,
    currentRouteConfig: store.app.currentRouteConfig,
    currentlang: store.app.lang,
  }
})(App);

App = withRouter(App);

export default App
