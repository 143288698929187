import collections from './collections';
import {axiosClient} from '../axios';
import {addNotification, axiosErrorHandler} from './notificationReducer';
import {themeComplement} from "../components/admin/AppAdmin/css/theme";
import AppConfig from '../components/config';
import moment from 'moment'


export function updateFormulaireMark(dispatch, uuid, data, cb) {
  let promise = axiosClient.put("/signatures/" + uuid, data);
  attachCallbacksPromise(
    dispatch,
    promise,
    "UPDATED",
    "UPDATE",
    "signatures",
    cb
  );
}

/**
 * Retourne le type en fonction de l'action
 * @param {*} action
 */
function getType(action) {
  let relations = {
    'RESET_DETAIL': 'RESET_DETAIL',
    'RESET_ERRORS': 'RESET_ERRORS',
    'RESET_ALL': 'RESET_ALL',
    'SHOW': 'DETAIL_FULFILLED',
    'INDEX': 'LIST_FULFILLED',
    'CREATE': 'CREATED',
    'UPDATE': 'UPDATED',
    'DELETE': 'DELETED',
  };
  return relations[action]
}

/**
 * Permet de gérer l'envoie de notifications
 * @param {*} dispatch
 * @param {*} collectionActions
 * @param {*} collectionName
 */
function sendNotification(dispatch, collectionActions, collectionName) {
  if (['CREATE', 'UPDATE', 'DELETE'].indexOf(collectionActions) > -1) {
    addNotification(dispatch, {
      message: getNotificationMessage(collectionActions, collectionName),
      bgColor: themeComplement.palette.notifications.success.color,
    })
  }
}

/**
 * Retourne le message de la notification
 * @param {*} collectionActions
 * @param {*} collectionName
 */
function getNotificationMessage(collectionActions, collectionName) {
  let config = collections.find(element => typeof element === 'object' && element.collectionName === collectionName),
    message = null;
  collectionName = collectionName.endsWith('s') ? collectionName.substring(0, collectionName.length - 1) : collectionName;

  if (config && Array.isArray(config.notifications) && config.notifications.find(element => typeof element === 'object' && element.type === collectionActions)) {
    message = config.notifications.find(element => typeof element === 'object' && element.type === collectionActions).message
  } else {
    message = capitalize(collectionName) + ' '
    switch (collectionActions) {
      case 'CREATE':
        message += ' : créé';
        break;
      case 'UPDATE':
        message += ' : modifié';
        break;
      case 'DELETE':
        message += ' : supprimé';
        break;
      case 'RECONCILIATION_CREATE':
        message = 'Votre demande de création de ' + message + ' a été mise en attente'
        break
      case 'RECONCILIATION_UPDATE':
        message = 'Votre demande de modification de ' + message + ' a été mise en attente'
        break
      case 'RECONCILIATION_DELETE':
        message = 'Votre demande de suppression de ' + message + ' a été mise en attente'
        break
      default :
        break;
    }
  }
  return message
}

/**
 * Fonction pour passer la première lettre en majuscule
 * @param {*} param0
 */
const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

/**
 * Retrouve si la collection passé en argument existe bien
 * @param  {[type]} element        [description]
 * @param  {[type]} collectionName [description]
 * @return {[type]}                [description]
 */
const findCollectionName = (collectionName, element) => {
  let elementCollectionName = null
  if (typeof element === 'object') elementCollectionName = element.collectionName
  else if (typeof element === 'string') elementCollectionName = element
  else throw new Error('Format dans collection.js erroné')

  return elementCollectionName === collectionName;
}

/**
 * Charge l'attribut d'une collection vérifie automatiquement si il est null ou déjà chargé
 * @param  {[type]}  dispatch        [description]
 * @param  {[type]}  attribute       [description]
 * @param  {[type]}  collectionName  [description]
 * @param  {[type]}  collectionStore [description]
 * @param  {[type]}  [data=null]     [description]
 * @param  {Boolean} [force=false]   [description]
 * @return {[type]}                  [description]
 */
export function loadCollectionAttribute(dispatch, attribute, collectionName, collectionStore, data = null, force = false) {
  //Vérifications
  if (!['list', 'detail'].includes(attribute)) throw new Error('Seul les attributs list et détail sont géré par cette méthode');
  if (!collections.some(findCollectionName.bind(this, collectionName))) throw new Error(`Collection ${collectionName} inconnue !`);
  if (!(typeof collectionStore === 'object' && Object.keys(collectionStore).every(k => Object.keys(defaultState).includes(k)))) {
    throw new Error(`CollectionStore non conforme`);
  }
  let action = null;
  if (!collectionStore.fetching) {
    if (attribute === 'list' && (!collectionStore[attribute] || force)) action = 'INDEX';
    if (attribute === 'detail' && ((!collectionStore[attribute] || collectionStore[attribute].uuid !== data.uuid) || force) && !!data && !!data.uuid) action = 'SHOW';
    if (attribute === 'detail' && (!data.uuid || !data) && !!collectionStore[attribute]) action = 'RESET_DETAIL'
  }

  if (!!action) collectionActions(dispatch, collectionName, action, data, null)
}

/**
 * Permet de lancer les actions par défauts sur les collections
 * @param {function} dispatch Function de dispatch de redux
 * @param {string} collectionName Nom de la collection a gérer
 * @param {string} action nom de l'action (SHOW, INDEX, CREATE, UPDATE, DELETE)
 * @param {object} data object des parametres a passer a axios
 * @param {function} cb Callback
 */
export function collectionActions(dispatch, collectionName, action, data = null, cb = null) {
  if (!dispatch) throw new Error(`Fonction dispatch indéfinie`)
  if (!collections.some(findCollectionName.bind(this, collectionName))) throw new Error(`Collection ${collectionName} inconnue !`);

  let promise = null,
    type = getType(action);

  switch (action) {
    case 'SHOW':
      dispatch({type: 'DETAIL_PENDING', collection: collectionName});
      promise = axiosClient.get(`/${collectionName}/${data.uuid}`, data);
      break;
    case 'INDEX' :
      dispatch({type: 'LIST_PENDING', collection: collectionName});
      promise = axiosClient.get(`/${collectionName}`, data);
      break;
    case 'CREATE' :
      promise = axiosClient.post(`/${collectionName}`, data);
      break;
    case 'UPDATE' :
      promise = axiosClient.put(`/${collectionName}/${data.uuid}`, data);
      break;
    case 'DELETE' :
      promise = axiosClient.delete(`/${collectionName}/${data.uuid}`, data);
      break;
    case 'RESET_DETAIL':
    case 'RESET_ERRORS':
    case 'RESET_ALL':
      dispatch({type: type, collection: collectionName});
      break;
    default:
      throw new Error(`Action ${action} inconnue !`)
  }

  attachCallbacksPromise(dispatch, promise, type, action, collectionName, cb)

}

/**
 * Rattache les callback a la promise axios
 * @param {*} dispatch
 * @param {*} promise
 * @param {*} type
 * @param {*} action
 * @param {*} collectionName
 * @param {*} cb
 */
function attachCallbacksPromise(dispatch, promise, type, action, collectionName, cb) {

  if (!!promise) {
    promise.then((response) => {
      if (typeof response.data !== 'object') throw new Error('Format de réponse erroné');
      dispatch({
        type,
        collection: collectionName,
        payload: response.data,
      });
      sendNotification(dispatch, action, collectionName);
      if (!!cb) cb(response.data)
    }).catch(error => {
      axiosErrorHandler(dispatch, error);
      if (!!error.response && error.response.status === 404) {
        addNotification(dispatch, {
          message: capitalize(collectionName) + ' non trouvé',
          bgColor: themeComplement.palette.notifications.error.color,
        })
      } else if (!!error.response &&
        !!error.response.data &&
        !!error.response.data.errors) {
        dispatch({
          type: 'ERRORS',
          collection: collectionName,
          payload: error.response.data.errors,
        })
      }
    })
  }
}

/**
 * Permet d'enregistrer une Reconciliation
 */
export function setReconciliation(dispatch, collectionName, action, data) {
  let reconciliationKey = AppConfig.reconciliationKey,
    reconciliationExpiration = AppConfig.reconciliationExpiration,
    dateExpiration = moment().add(reconciliationExpiration, 'minutes').format(),
    reconciliations = localStorage.getItem(reconciliationKey) ? JSON.parse(localStorage.getItem(reconciliationKey)) : [],
    currentId = 0

  reconciliations.forEach(r => {
    if (r.id > currentId) currentId = r.id
  })
  currentId++;

  let currentReconciliation = {
    id: currentId,
    collectionName,
    action,
    data,
    dateExpiration,
  }
  reconciliations.push(currentReconciliation)

  setLocalStorageReconciliation(reconciliations, reconciliationKey)

  addNotification(dispatch, {
    message: getNotificationMessage('RECONCILIATION_' + action, collectionName),
    bgColor: themeComplement.palette.notifications.info.color,
  })
}

export function removeReconciliations(array_ids) {
  let reconciliationKey = AppConfig.reconciliationKey,
    reconciliations = localStorage.getItem(reconciliationKey) ? JSON.parse(localStorage.getItem(reconciliationKey)) : []
  reconciliations = reconciliations.filter(r => !array_ids.includes(r.id))

  setLocalStorageReconciliation(reconciliations, reconciliationKey)

}

function setLocalStorageReconciliation(reconciliations, reconciliationKey) {
  if (reconciliations.length > 0)
    localStorage.setItem(reconciliationKey, JSON.stringify(reconciliations))
  else {
    localStorage.removeItem(reconciliationKey)
  }
  // Permet de mettre a jour le storage app qui écoute cet event.
  window.dispatchEvent(new Event('reconciliationStorage'));
}

/**
 * État initial par défaut des collections
 */
let defaultState = {
  fetching: false,
  fetched: false,
  list: null,
  detail: null,
  errors: null,
};

/**
 * État initial par défaut du store
 */
const initialState = collections.reduce((obj, current) => {
  if (typeof current === 'object') obj[current.collectionName] = defaultState
  else if (typeof current === 'string') obj[current] = defaultState
  else throw new Error('Format dans collection.js erroné')
  return obj
}, {});

/**
 * Storage
 */
export default (state = initialState, action) => {
  if (action.collection === undefined) return state;

  let collection = action.collection;

  switch (action.type) {
    case 'RESET_ALL': {
      return {
        ...state,
        [collection]: defaultState,
      }
    }
    case 'RESET_ERRORS': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: false,
          errors: null,
        },
      }
    }
    case 'RESET_DETAIL': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: null,
        },
      }
    }
    case 'DETAIL_PENDING': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: true,
          fetched: false,
          detail: null,
        },
      }
    }
    case 'DETAIL_FULFILLED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload,
        },
      }
    }
    case 'LIST_PENDING': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: true,
          fetched: false,
          list: null,
        },
      }
    }
    case 'LIST_FULFILLED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          list: action.payload,
        },
      }
    }
    case 'CREATED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload,
          list: state[collection].list ? state[collection].list.concat([action.payload]) : null,

        },
      }
    }
    case 'UPDATED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: action.payload,
          list: state[collection].list ? state[collection].list.map(item => item.uuid === action.payload.uuid ? action.payload : item) : null,

        },
      }
    }
    case 'DELETED': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: true,
          detail: null,
          list: state[collection].list ? state[collection].list.filter(item => item.uuid !== action.payload.uuid) : null,

        },
      }
    }
    case 'ERRORS': {
      return {
        ...state,
        [collection]: {
          ...state[collection],
          fetching: false,
          fetched: false,
          errors: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
