import React, {Component} from 'react';
import SejoursCss from './css/SejoursCss'
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import CollectionCrud from '../../common/Components/CollectionCrud';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { collectionActions } from '../../../reducers/collectionsReducer';
import { PersonAddDisabled, Receipt, LooksOne, LooksTwo } from '@material-ui/icons';
import { axiosClient } from '../../../axios';
import { addNotification, axiosErrorHandler } from '../../../reducers/notificationReducer';
import { themeComplement } from '../AppAdmin/css/theme';

class Detail extends Component {
  state = {
    openDeleteFriendModal: false,
    selectedInscription: null,
  }

  getDate(string) {
    if (!string) {
      return ''
    }
    let date = new Date(string)
    let options = {day: 'numeric', month: 'numeric', year: 'numeric'}
    return date.toLocaleDateString('fr-FR', options)
  }

  componentDidMount() {
    let {dispatch} = this.props;
    collectionActions(
      dispatch,
      'sejours',
      'SHOW',
      {uuid: this.props.match.params.uuid},
    )

    collectionActions(
      dispatch,
      'inscriptions',
      'INDEX',
      {params: {sejour_uuid: this.props.match.params.uuid}}
    )
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle() {
    return <><Receipt/><span>{'Suppression de l’inscription'}</span></>
  }

  getDeleteModalContent(line) {
    let enfant = ''
    let places = ''

    if (line && line.enfant_nom) {
      enfant = line.enfant_nom + ' ' + line.enfant_prenom
    }

    if (line && line.nbr_places) {
      places = (line.nbr_places > 1 ? 'les ' + line.nbr_places + ' places' : 'la place')
    }

    return <Typography>{'Êtes vous sûr de vouloir supprimer l’inscription de ' + enfant + ' et de recréditer '+ places +' ?'}</Typography>
  }


  getTitle() {
    if (this.props.sejoursStore.detail) {
      return 'pour séjour ' + this.props.sejoursStore.detail.libelle
        + ' du ' + this.getDate(this.props.sejoursStore.detail.date_debut)
        + ' au ' + this.getDate(this.props.sejoursStore.detail.date_fin)
    }
    return null
  }

  closeDeleteFriendModalHandler() {
    // const {dispatch} = this.props;
    this.setState({
      openDeleteFriendModal: false,
      selectedInscription: {},
    }, () => {
    })
  }

  onClickDeleteFriendHandler(inscription) {
    // const {dispatch} = this.props;
    this.setState({
      openDeleteFriendModal: true,
      selectedInscription: inscription,
    }, () => {
    })
  }

  validDeleteFriendModalHandler() {
    const uuid = this.state.selectedInscription.uuid

      axiosClient.delete('inscriptions/' + uuid + '/friend', {})
      .then(response => {
        collectionActions(
          this.props.dispatch,
          'inscriptions',
          'INDEX',
          {params: {sejour_uuid: this.props.match.params.uuid}}
        )
        this.closeDeleteFriendModalHandler()
        addNotification(this.props.dispatch, {
          message: 'ami⋅e supprimé⋅e avec succès, place recréditée',
          bgColor: themeComplement.palette.notifications.success.color
        });
      }).catch(error => axiosErrorHandler(this.props.dispatch, error));
  }

  getDeleteFriendModalContent() {
    const {classes} = this.props
    const enfant = this.state.selectedInscription ? this.state.selectedInscription.enfant_prenom : ''
    const ami = this.state.selectedInscription ? this.state.selectedInscription.copain_prenom : ''

    return (
      <DialogContent className={classes.dialogContentSmall}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography>{'Êtes vous sûr de vouloir supprimer l’inscription de ' + ami + ', l’ami⋅e de ' + enfant + ' et de recréditer 1 place ?'}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    )
  }

  getDeleteFriendModal() {
    return (
      <Dialog
        open={this.state.openDeleteFriendModal}
        onClose={this.closeDeleteFriendModalHandler.bind(this)}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          Supprimer l'ami pour cette inscription
        </DialogTitle>
        <div>
        {this.getDeleteFriendModalContent()}
        </div>
        <DialogActions>
          <Button onClick={this.closeDeleteFriendModalHandler.bind(this)}>
            Annuler
          </Button>
          <Button onClick={this.validDeleteFriendModalHandler.bind(this)} color="primary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getDeleteFriendButton(inscription) {
    return (
      <IconButton
      aria-label="Supprimer ami⋅e"
      color="primary"
      title="supprimer l'ami⋅e de l'inscription"
      key={"supp-ami-" + inscription.uuid}
      disabled={inscription.nbr_places === 1}
      onClick={this.onClickDeleteFriendHandler.bind(this, inscription)}
    >
      <PersonAddDisabled/>
    </IconButton>
    )
  }

  getFormulairesUrlsButton(inscription) {
    return (
      <>
        <IconButton
          aria-label="lien vers le formulaire de l'enfant"
          color="primary"
          title="lien vers le formulaire vierge de l'enfant"
          key={"form-enfant-" + inscription.uuid}
          disabled={!inscription.formulaire_vierge_enfant_url}
          onClick={() => window.open(inscription.formulaire_vierge_enfant_url, '_blank')}
        >
          <LooksOne/>
        </IconButton>
        <IconButton
          aria-label="lien vers le formulaire vierge de l'ami⋅e"
          color="primary"
          title="lien vers le formulaire de l'ami⋅e"
          key={"form-copain-" + inscription.uuid}
          disabled={!inscription.formulaire_vierge_copain_url}
          onClick={() => window.open(inscription.formulaire_vierge_copain_url, '_blank')}
        >
          <LooksTwo/>
        </IconButton>
      </>
    )
  }


  render() {
    const {classes} = this.props

    return (
      <Grid container spacing={2} className={classes.container}>
        <Typography
          variant="button"
          gutterBottom
          className={classes.sectionTitle}
        >
          Inscriptions {this.getTitle()}
        </Typography>

        <CollectionCrud
          collectionName={'inscriptions'}
          loadDatas={false}
          showBtnEdit={false}
          showBtnAdd={false}
          showBtnDelete={true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          additionnalControllers={[
            this.getDeleteFriendButton.bind(this),
            this.getFormulairesUrlsButton.bind(this),
          ]}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: 'created_at',
            rowsPerPageOptions: [50, 100],
          }}
          cellsConfig={[
            {
              datakey: 'created_at',
              title: 'Inscription à',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'parents_nom',
              title: 'Nom parent',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'parents_prenom',
              title: 'Prénom',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'enfant_nom',
              title: 'Nom enfant',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'enfant_prenom',
              title: 'Prénom',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'navette_arret_name',
              title: 'Arrêt',
              sortable: true,
              searchable: true,
              format: (inscription) => inscription.navette_arret_name ? inscription.navette_arret_name : '-',
            },
            {
              title: "Ami⋅e",
              format: function (obj) {
                if (obj.nbr_places === 1) {
                  return "-"
                }
                return obj.copain_nom + ' ' + obj.copain_prenom
              },
              sortable: true,
              searchable: true,
            }
          ]}
        />
        {this.getDeleteFriendModal()}
      </Grid>
    );
  }
}

Detail = withStyles(SejoursCss)(Detail);

Detail = connect((store) => {
  return {
    sejoursStore: store.collections.sejours,
    inscriptionsStore: store.collections.inscriptions,
  }
})(Detail);

export default Detail
