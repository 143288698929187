import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core';
import {Style} from '@material-ui/icons';
import {loadCollectionAttribute} from "../../../reducers/collectionsReducer";
import {TextInput} from '@oniti/oniti-inputs';
import RoleCss from './css/RoleCss';
import CollectionCrud from '../../common/Components/CollectionCrud';

class Role extends Component {
  state = {
    rightsList: [],
    roleProps: null,
    rightsRole: [],
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rightsStore.list !== prevState.rightsList) {
      return {
        ...prevState,
        rightsList: nextProps.rightsStore.list,
      };
    }
    if (nextProps.rolesStore.detail !== prevState.roleProps) {
      return {
        ...prevState,
        roleProps: nextProps.rolesStore.detail,
        rightsRole: !nextProps.rolesStore.detail ? [] : nextProps.rolesStore.detail.rights.map(r => r.uuid),
      };
    }

    return null;
  }

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    if (this.props.online)
      loadCollectionAttribute(this.props.dispatch, 'list', 'rights', this.props.rightsStore)
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle() {
    return <><Style/> <span>{'Suppression du rôle'}</span></>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer ce rôle ?'}</Typography>;
  }

  getCreateUpdateModalTitle(role) {
    return <>
      <Style/> <span>{!!role ? 'Édition du rôle : ' + role.libelle : 'Création d\'un nouveau rôle'}</span>
    </>;
  }

  /**
   * Handler sur les changement des Checkboxs
   * @param  {[type]} uuid [description]
   * @return {[type]}      [description]
   */
  handleChangeCheckBox(uuid) {
    let {rightsRole} = this.state;
    if (rightsRole.includes(uuid)) rightsRole = rightsRole.filter(value => value !== uuid);
    else rightsRole.push(uuid);

    this.setState({
      rightsRole,
    });
  }

  /**
   * Retourne les Checkboxs
   * @return {[type]} [description]
   */
  getCheckBoxs() {
    if (!this.state.rightsList) return null;
    return this.state.rightsList.map(right => {

      return (
        <FormControlLabel
          key={right.uuid}
          control={
            <Switch
              checked={this.state.rightsRole.includes(right.uuid)}
              onChange={this.handleChangeCheckBox.bind(this, right.uuid)}
              value={right.uuid}
              color="primary"
            />
          }
          label={right.libelle}
        />
      );
    })
  }

  /**
   * Retourne les erreurs sur les droits
   * @return {[type]} [description]
   */
  getErrorsRights(rolesStore) {
    if (rolesStore.errors && rolesStore.errors.rights) {
      return rolesStore.errors.rights.map((err, index) => <span style={{display: 'block'}} key={index}>{err}</span>);
    } else return null;
  }

  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(role, rolesStore, onChangeHandler) {
    let {libelle} = role;
    const {classes} = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libellé"
            value={libelle}
            margin="normal"
            collectionStore={rolesStore}
            name='libelle'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className={classes.divider}>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Droits Associés</FormLabel>
            <FormGroup>
              <div className={classes.error}>
                {this.getErrorsRights(rolesStore)}
              </div>
              {this.getCheckBoxs()}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes} = this.props;
    return (
      <Grid container spacing={2} className={classes.container}>
        <CollectionCrud
          collectionName={'roles'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          extraDatasForm={{
            rights: this.state.rightsRole,
          }}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "libelle",
          }}
          cellsConfig={[
            {
              datakey: 'libelle',
              title: 'Libelle',
              sortable: true,
              searchable: true,
            },
          ]}
          enableOffLineModification={true}
          reconciliationFieldsToCompare={[
            {
              datakey: 'libelle',
              title: 'Libelle',
              sortable: true,
              searchable: true,
            },
          ]}
        />
      </Grid>
    );
  }
}

Role = withStyles(RoleCss)(Role);

Role = connect((store) => {
  return {
    rightsStore: store.collections.rights,
    rolesStore: store.collections.roles,
    online: store.app.online,
  }
})(Role);

export default Role
