import React, {Component} from 'react';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ErrorsPagesCss from './css/ErrorsPagesCss';
import {Grid, Paper, Typography, withStyles} from '@material-ui/core';

class Error404 extends Component {
  render() {
    const {classes, ...props} = this.props;

    return (
      <Grid
        container
        alignItems='center'
        justify='center'
        className={classes.errorContainer}
        {...props}
      >
        <Paper elevation={20} className={classes.paper}>
          <Grid
            container
            alignItems='center'
          >
            <Grid item lg={4}>
              <ErrorOutline
                className={classes.errorIcon}
                color='primary'
              />
            </Grid>
            <Grid item lg={8}>
              <Typography
                variant='h1'
                className={classes.title}
              >
                404 Error
              </Typography>
              <Typography
                color='primary'
                variant='h2'
                className={classes.subtitle}
              >
                Page non trouvée
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

Error404 = withStyles(ErrorsPagesCss)(Error404);

export default Error404
