import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {AppBar, Grid, Tab, Tabs, Typography} from '@material-ui/core/index';
import {withStyles} from '@material-ui/core/styles/index';

import SwipeableViews from 'react-swipeable-views';
import User from '../User';
import Role from '../Role';
import ParametresCss from './css/ParametresCss';

class TabContainer extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
  }

  render() {
    let {children, dir} = this.props
    return (
      <Typography component="div" dir={dir} style={{padding: 8 * 3}}>
        {children}
      </Typography>
    )
  }
}

class Parametres extends Component {

  state = {
    value: 0,
  }

  handleChange = (event, value) => {
    this.setState({value})
  }

  handleChangeIndex = index => {
    this.setState({value: index})
  }

  render() {
    return (
      <Grid item xs={12}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Utilisateurs" classes={this.props.classes}/>
            <Tab label="Rôles" classes={this.props.classes}/>
          </Tabs>
        </AppBar>
        <SwipeableViews axis={'x'}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={'ltr'}>
            <User/>
          </TabContainer>
          <TabContainer dir={'ltr'}>
            <Role/>
          </TabContainer>
        </SwipeableViews>
      </Grid>
    )
  }
}

Parametres = withStyles(ParametresCss)(Parametres)

export default Parametres
