import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, withStyles} from '@material-ui/core'
import {CheckCircle, List, RadioButtonUnchecked, HowToReg, Exposure, SwapHoriz, ArrowBack, ArrowForward} from '@material-ui/icons'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import CollectionCrud from '../../common/Components/CollectionCrud'
import SejoursCss from './css/SejoursCss'
import {NumberInput, TextInput} from '@oniti/oniti-inputs';
import {buildRoute} from '../../../router/Tools'
import { collectionActions } from '../../../reducers/collectionsReducer'
import { axiosClient } from '../../../axios'
import { addNotification, axiosErrorHandler } from '../../../reducers/notificationReducer'
import { themeComplement } from '../AppAdmin/css/theme'

class Sejours extends Component {
  state = {
    openPreleveModal: false,
    quantite: 1,
    source: '',
    selectedSejour: null,
    openCreditModal: false,
    openContingentModal: false,
    openTransfertModal: false,
  }

  componentDidMount() {
    let {dispatch} = this.props;
    collectionActions(dispatch, 'sejours', 'INDEX', {})
  }

  getDate(string) {
    if (!string) {
      return ''
    }
    let date = new Date(string)
    let options = {day: 'numeric', month: 'numeric', year: 'numeric'}
    return date.toLocaleDateString('fr-FR', options)
  }

  inscriptionClickHandler(line) {
      this.props.history.push(
        buildRoute(
          '/admin/sejours/:uuid', {uuid: line.uuid}
        )
      )
  }

  formulaireClickHandler(line) {
    this.props.history.push(
      buildRoute(
        '/admin/formulaires/:uuid', {uuid: line.uuid}
      )
    )
  }

  closeOperationModalHandler(name) {
    this.setState({
      [name]: false,
      selectedSejour: {},
    }, () => {
    })
  }

  onClickOperationtHandler(sejour, name) {
    this.setState({
      [name]: true,
      selectedSejour: sejour,
      quantite: 1,
      source: '',
    }, () => {
    })
  }

  validOperationModalHandler(action) {
    let actionPath = ""
    let actionResponse = ""
    let closeState = ""

    const uuid = this.state.selectedSejour.uuid
    const quantite = this.state.quantite

    let data = {
      places: quantite,
    }

    switch (action) {
      case "credit":
        actionPath = '/crediter'
        actionResponse = ' place(s) ajouté(s) avec succès'
        closeState = "openCreditModal"
        break
      case "prelevement":
        actionPath = '/prelevement'
        actionResponse = ' place(s) prélevée(s) avec succès'
        closeState = "openPreleveModal"
        break
      case "contingent":
        actionPath = '/contingent'
        actionResponse = ' inscription(s) traitée(s) avec succès'
        closeState = "openContingentModal"
        break
      case "transfert":
        actionPath = '/transfert'
        actionResponse = ' place(s) transférée(s) avec succès'
        closeState = "openTransfertModal"
        data.source = this.state.source
        break
      default:
        console.error("Action inconnue")
        break
    }
      axiosClient.post('sejours/' + uuid + actionPath, data)
      .then(response => {
        collectionActions(this.props.dispatch, 'sejours', 'INDEX', {})
        this.closeOperationModalHandler(closeState)
        addNotification(this.props.dispatch, {
          message: '' + response.data.places + actionResponse,
          bgColor: themeComplement.palette.notifications.success.color
        });
      }).catch(error => axiosErrorHandler(this.props.dispatch, error));
  }

  getPreleveModalContent() {
    const {classes} = this.props

    return (
      <DialogContent className={classes.dialogContentSmall}>
        <Grid container className={classes.container}>
          <Grid item xs={6}>
            <NumberInput
                id="places"
                label="Nombre de place à prélever"
                value={this.state.quantite}
                margin="normal"
                name="places"
                fullWidth
                collectionStore={this.props.sejoursStore}
                onChangeHandler={(name, event) => this.setState({quantite: event.target.value})}
            />
          </Grid>
        </Grid>
      </DialogContent>
    )
  }

  getPreleveModal() {
    return (
      <Dialog
        open={this.state.openPreleveModal}
        onClose={this.closeOperationModalHandler.bind(this,"openPreleveModal")}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          Prélever des places dans ce séjour
        </DialogTitle>
        <div>
        {this.getPreleveModalContent()}
        </div>
        <DialogActions>
          <Button onClick={this.closeOperationModalHandler.bind(this,"openPreleveModal")}>
            Fermer
          </Button>
          <Button onClick={this.validOperationModalHandler.bind(this,"prelevement")} color="primary">
            Prélever
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getCrediteModalContent() {
    const {classes} = this.props

    return (
      <DialogContent className={classes.dialogContentSmall}>
        <Grid container className={classes.container}>
          <Grid item xs={6}>
            <NumberInput
                id="places"
                label="Nombre de place à crediter"
                value={this.state.quantite}
                margin="normal"
                name="places"
                fullWidth
                collectionStore={this.props.sejoursStore}
                onChangeHandler={(name, event) => this.setState({quantite: event.target.value})}
            />
          </Grid>
        </Grid>
      </DialogContent>
    )
  }

  getContingentModal() {
    return (
      <Dialog
        open={this.state.openContingentModal}
        onClose={this.closeOperationModalHandler.bind(this,"openContingentModal")}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
        Inscrire ou désinscrire (contingent de places manuelles)
        </DialogTitle>
        <div>
        {this.getContingentModalContent()}
        </div>
        <DialogActions>
          <Button onClick={this.closeOperationModalHandler.bind(this,"openContingentModal")}>
            Fermer
          </Button>
          <Button onClick={this.validOperationModalHandler.bind(this,"contingent")} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getContingentModalContent() {
    const {classes} = this.props

    return (
      <DialogContent className={classes.dialogContentSmall}>
        <Grid container className={classes.container}>
          <Grid item xs={6}>
            <NumberInput
                id="places"
                label="Nombre d'inscriptions"
                helperText="(entrer un nombre négatif pour les désinscriptions)"
                value={this.state.quantite}
                margin="normal"
                name="places"
                fullWidth
                collectionStore={this.props.sejoursStore}
                onChangeHandler={(name, event) => this.setState({quantite: event.target.value})}
                type="number"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.validOperationModalHandler("contingent")
                  }
                }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    )
  }

  getTransfertModal() {
    return (
      <Dialog
        open={this.state.openTransfertModal}
        onClose={this.closeOperationModalHandler.bind(this,"openTransfertModal")}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          Transférer des places depuis ou vers Internet
        </DialogTitle>
        <div>
        {this.getTransfertModalContent()}
        </div>
        <DialogActions>
          <Button onClick={this.closeOperationModalHandler.bind(this,"openTransfertModal")}>
            Fermer
          </Button>
          <Button onClick={this.validOperationModalHandler.bind(this,"transfert")} color="primary">
            Transférer
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getTransfertModalContent() {
    const {classes} = this.props

    return (
      <DialogContent className={classes.dialogContentSmall}>
        <Grid container className={classes.container}>
          <Grid item xs={6}>
            <NumberInput
                id="places"
                label="Nombre de place à transférer"
                value={this.state.quantite}
                margin="normal"
                name="places"
                fullWidth
                collectionStore={this.props.sejoursStore}
                onChangeHandler={(name, event) => this.setState({quantite: event.target.value})}
                type="number"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.validOperationModalHandler("transfert")
                  }
                }}
            />
          </Grid>
          <Grid item xs={6}>
          <TextInput
              id="source"
              label="Sens du transfert"
              value={this.state.source}
              margin="normal"
              name="source"
              fullWidth
              collectionStore={this.props.sejoursStore}
              onChangeHandler={(name, event) => this.setState({source: event.target.value})}
              select
            >
              <MenuItem value="internet">
                <ArrowForward className={classes.iconTransfert}/>
                Depuis internet vers place manuelle
              </MenuItem>
              <MenuItem value="manuelle">
                <ArrowBack className={classes.iconTransfert}/>
                Depuis place manuelle vers internet
              </MenuItem>
            </TextInput>
          </Grid>
        </Grid>
      </DialogContent>
    )
  }

  getCrediteModal() {
    return (
      <Dialog
        open={this.state.openCreditModal}
        onClose={this.closeOperationModalHandler.bind(this,"openCreditModal")}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          Ajouter des places dans ce séjour
        </DialogTitle>
        <div>
        {this.getCrediteModalContent()}
        </div>
        <DialogActions>
          <Button onClick={this.closeOperationModalHandler.bind(this,"openCreditModal")}>
            Fermer
          </Button>
          <Button onClick={this.validOperationModalHandler.bind(this,"credit")} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getActionsInternetButtons(line) {
    var btns = []

    /*btns.push(
      <IconButton
        aria-label="Prélever"
        key="prelever"
        color="primary"
        title="Prélever des places"
        onClick={this.onClickOperationtHandler.bind(this, line,'openPreleveModal')}
        disabled={line.plein}
      >
        <Eject/>
      </IconButton>
    )

    btns.push(
      <IconButton
        aria-label="Créditer"
        key="crediter"
        color="primary"
        title="Créditer des places"
        onClick={this.onClickOperationtHandler.bind(this, line,'openCreditModal')}
      >
        <Add/>
      </IconButton>
    )*/

    btns.push(
        <IconButton
          aria-label="Inscriptions"
          key="incriptions"
          color="primary"
          title="Voir les inscriptions"
          onClick={this.inscriptionClickHandler.bind(this, line)}
        >
          <List/>
        </IconButton>
    )

    btns.push(
        <IconButton
          aria-label="Formulaires"
          key="formulaires"
          color="primary"
          title="Voir les formulaires signés"
          onClick={this.formulaireClickHandler.bind(this, line)}
        >
          <HowToReg/>
        </IconButton>
    )

    return btns
  }

  getActionsManuellesButtons(line) {
    var btns = []

    btns.push(
      <IconButton
        aria-label="Contingent"
        key="contingent"
        color="primary"
        title="Inscrire ou désinscrire (dans le contingent de places manuelles)"
        onClick={this.onClickOperationtHandler.bind(this, line,'openContingentModal')}
      >
        <Exposure/>
      </IconButton>
    )

    // transférer des places
    btns.push(
      <IconButton
        aria-label="Transférer"
        key="transfert"
        color="primary"
        title="Transférer des places depuis ou vers Internet"
        onClick={this.onClickOperationtHandler.bind(this, line,'openTransfertModal')}
      >
        <SwapHoriz/>
      </IconButton>
    )

    return btns
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container spacing={2} className={classes.container}>
        <CollectionCrud
          collectionName={'sejours'}
          loadDatas={false}
          showBtnEdit={false}
          showBtnAdd={false}
          showBtnDelete={false}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            rowsPerPageOptions: [20, 50, 100],
          }}
          cellsConfig={[
            {
              datakey: 'libelle',
              title: 'Libelle',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'identifiant',
              title: 'Id',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'date_debut',
              title: 'Début',
              sortable: true,
              searchable: true,
              format: (obj, key) => {
                return this.getDate(obj[key])
              },
            },
            {
              datakey: 'date_fin',
              title: 'Fin',
              sortable: true,
              searchable: true,
              format: (obj, key) => {
                return this.getDate(obj[key])
              },
            },
            {
              datakey: 'inscriptions_internet',
              title: 'Inscriptions internet',
              sortable: true,
              searchable: true,
              format: (obj, key) => {
                return obj['inscriptions_count'] + ' / ' + obj['places']
              },
            },
            {
              datakey: 'plein',
              title: 'Plein',
              sortable: true,
              searchable: false,
              format: (obj, key) => {
                return !!obj[key]
                  ? <CheckCircle color="primary"/>  // 'Oui'
                  : <RadioButtonUnchecked/>  // 'Non'
              },
            },
            {
              format: this.getActionsInternetButtons.bind(this),
              title: 'Actions internet',
              sortable: false,
            },
            {
              datakey: 'inscriptions_manuelles_recap',
              title: 'Inscriptions manuelles',
              sortable: true,
              searchable: true,
              format: (obj, key) => {
                return obj['inscriptions_manuelles'] + ' / ' + obj['places_manuelles']
              },
            },
            {
              datakey: 'plein_internet',
              title: 'Plein',
              format: (obj, key) => {
                return (obj['inscriptions_manuelles'] < obj['places_manuelles'] ? <RadioButtonUnchecked color="disabled"/> : <CheckCircle color="disabled"/>)
              }
            },
            {
              format: this.getActionsManuellesButtons.bind(this),
              title: 'Actions manuelles',
              sortable: false,
            },
          ]}
        />
        {this.getPreleveModal()}
        {this.getCrediteModal()}
        {this.getContingentModal()}
        {this.getTransfertModal()}
      </Grid>
    );
  }
}

Sejours = withStyles(SejoursCss)(Sejours)

Sejours = connect((store) => {
  return {
    sejoursStore: store.collections.sejours,
  }
})(Sejours);

export default Sejours
