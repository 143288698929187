/**
 * Collections a gérer de manière générique
 */
export default [
  {
    collectionName: 'users',
    notifications: [
      {
        type: 'CREATE',
        message: 'Utilisateur créé',
      },
      {
        type: 'UPDATE',
        message: 'Utilisateur mis à jour',
      },
      {
        type: 'DELETE',
        message: 'Utilisateur supprimé',
      },
    ],
  },
  'roles',
  'rights',
  {
    collectionName: 'sejours',
    notifications: [
      {
        type: 'CREATE',
        message: 'Séjour créé',
      },
      {
        type: 'UPDATE',
        message: 'Séjour mis à jour',
      },
      {
        type: 'DELETE',
        message: 'Séjour supprimé',
      },
    ],
  },
  {
    collectionName: 'signatures',
    notifications: [
      {
        type: 'CREATE',
        message: 'Formulaire créé',
      },
      {
        type: 'UPDATE',
        message: 'Formulaire mis à jour',
      },
      {
        type: 'DELETE',
        message: 'Formulaire supprimé',
      },
    ],
  },
  'inscriptions',
  'signatures',
  'navettes',
  'weeks',
  'dossiers',
]
