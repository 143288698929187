import User from '../../User';
import Role from '../../Role';
import AuthAdmin from '../../Auth';
import Parametres from '../../Parametres';
import Sejours from '../../Sejours';
import SejourDetail from '../../Sejours/Detail';
import Formulaires from '../../Formulaires';
import Signatures from '../../Signatures';
import Navettes from '../../Navettes';
import Dossiers from '../../Dossiers';

export const routes = [
  {path: '/admin/users', component: User, exact: true, public: false, right: 'admin-crud-users'},
  {path: '/admin/roles', component: Role, exact: true, public: false, right: 'admin-crud-roles'},
  {path: '/admin/auth', component: AuthAdmin, exact: false, public: true, noContainer: true},
  {path: '/admin', component: Sejours, exact: true, public: false, right: 'admin-crud-sejours'},
  {path: '/admin/signatures', component: Signatures, exact: true, public: false, right: 'admin-crud-sejours'},
  {path: '/admin/dossiers', component: Dossiers, exact: true, public: false, right: 'admin-crud-sejours'},
  {path: '/admin/navettes', component: Navettes, exact: true, public: false, right: 'admin-crud-sejours'},
  {
    path: '/admin/parametres',
    component: Parametres,
    exact: true,
    public: false,
    right: 'admin-crud-parametres'
  },
  {
    path: '/admin/sejours/:uuid',
    component: SejourDetail,
    exact: true,
    public: false,
    right: 'admin-crud-sejours'
  },  {
    path: '/admin/formulaires/:uuid',
    component: Formulaires,
    exact: true,
    public: false,
    right: 'admin-crud-sejours'
  },
];
