import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Grid, MenuItem, Typography, withStyles} from '@material-ui/core';
import {Person} from '@material-ui/icons';
import {loadCollectionAttribute} from "../../../reducers/collectionsReducer";
import {TextInput} from '@oniti/oniti-inputs';
import UserCss from './css/UserCss';
import CollectionCrud from '../../common/Components/CollectionCrud';

class User extends Component {

  /**
   * Lors du montage du composant
   */
  componentDidMount() {
    if (this.props.online)
      loadCollectionAttribute(this.props.dispatch, 'list', 'roles', this.props.rolesStore)
  }

  /**
   * Title de la modal de suppression
   * @return {[type]} [description]
   */
  getDeleteModalTitle() {
    return <><Person/> <span>{'Suppression de l\'utilisateur'}</span></>;
  }

  getDeleteModalContent() {
    return <Typography>{'Êtes vous sûr de vouloir supprimer cet utilisateur ?'}</Typography>;
  }

  getCreateUpdateModalTitle(user) {
    return <><Person/>
      <span>{!!user ? 'Édition de l\'utilisateur : ' + user.firstname + ' ' + user.lastname : 'Création d\'un nouvel utilisateur'}</span>
    </>;
  }

  /**
   * Construction de la liste des rôles
   * @returns {*}
   */
  getRoles() {
    if (!this.props.rolesStore.list) return [];

    return this.props.rolesStore.list.map(role => {
      return (
        <MenuItem value={role.uuid} key={role.uuid}>
          {role.libelle}
        </MenuItem>
      );
    })
  }

  /**
   * Retourne le formulaire
   * @returns {*}
   */
  getCreateUpdateModalContent(user, usersStore, onChangeHandler) {
    let {firstname, lastname, email, role_uuid} = user;
    const {classes} = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            id="firstname"
            label="Prénom"
            value={firstname}
            margin="normal"
            collectionStore={usersStore}
            name='firstname'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="lastname"
            label="Nom"
            value={lastname}
            margin="normal"
            collectionStore={usersStore}
            name='lastname'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="email"
            label="Email"
            value={email}
            margin="normal"
            collectionStore={usersStore}
            name='email'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="role_uuid"
            label="Rôle"
            value={role_uuid}
            margin="normal"
            collectionStore={usersStore}
            name='role_uuid'
            onChangeHandler={onChangeHandler}
            className={classes.selectContainer}
            select
          >
            {this.getRoles()}
          </TextInput>
        </Grid>
      </Grid>
    );
  }

  /**
   * retourne la configuration des cellules
   * @return {[type]} [description]
   */
  getCellsConfig() {
    return [
      {
        datakey: "firstname",
        title: "Prénom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "lastname",
        title: "Nom",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "email",
        title: "Email",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "role.libelle",
        title: "Rôle",
        sortable: true,
        searchable: true,
      },
    ];
  }

  getExportBtn() {
    return (
      <Button
        color="primary"
        variant="contained"
        href={process.env.REACT_APP_API_URL + '/users/export'}
        target='_blank'
        title='export Utilisateurs'
      >
        Export des Utilisateurs
      </Button>
    )
  }

  /**
   * Fonction de rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {classes} = this.props;
    return (
      <Grid container spacing={2} className={classes.container}>
        <CollectionCrud
          collectionName={'users'}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "libelle",
          }}
          dataTableExtraNodes={[
            {
              element: this.getExportBtn(),
              position: 'top-left',
            },
          ]}
          cellsConfig={this.getCellsConfig()}
          canLoadDetailFromCache={true}
          enableOffLineModification={true}
          reconciliationFieldsToCompare={[
            {
              datakey: 'firstname',
              title: 'Nom',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'lastname',
              title: 'Prénom',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'email',
              title: 'Email',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'role.libelle',
              title: 'Role',
              format: (objet) => this.props.rolesStore.list.find(r => r.uuid === objet.role_uuid).libelle, // Attention lors d'une création seul les données du formulaire sont disponibles
              sortable: true,
              searchable: true,
            },
          ]}
        />
      </Grid>
    );
  }
}

User = withStyles(UserCss)(User);

User = connect((store) => {
  return {
    rolesStore: store.collections.roles,
    online: store.app.online,
  }
})(User);

export default User
