export default theme => ({
  crudTitle: {
    float: 'left',
    marginBottom: 0,
    padding: '8px 0',
    color: theme.palette.primary.main,
  },
  divider: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  sectionTitle: {
    marginBottom: '1em',
    color: theme.palette.primary.main,
  },
  iconTransfert: {
    color: theme.palette.primary.main,
    marginRight: '0.5em',
    fontSize: '1em',
  },
})
