import React, {Component} from 'react';
import {connect} from 'react-redux';
import LeftMenuCss from './css/LeftMenuCss';
import classNames from 'classnames';
import {Divider, Drawer, IconButton, List, Typography, withStyles} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import DefaultLogo from '../../../assets/img/default-logo-63px.png';
import LogoArbre from '../../../assets/img/logo-arbre-63px.png';

import LeftMenuButton from "./LeftMenuButton";
import { HowToReg, DirectionsBus, Payment } from '@material-ui/icons';

class LeftMenu extends Component {
  state = {
    open: true,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {
        ...prevState,
        open: nextProps.open,
      };
    }

    return null;
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    const {classes, noContainer, user} = this.props,
      buttons = {
        sejours: {
          label: "Séjours",
          link: "/admin",
          matchingPath: ["/admin"],
          icon: <FlightTakeoffIcon/>,
        },
        signatures: {
          label: "Fiches signées",
          link: "/admin/signatures",
          matchingPath: ["/admin/signatures"],
          icon: <HowToReg/>,
        },
        dossiers: {
          label: "Dossiers",
          link: "/admin/dossiers",
          matchingPath: ["/admin/dossiers"],
          icon: <Payment/>,
        },
        navettes: {
          label: "Navettes",
          link: "/admin/navettes",
          matchingPath: ["/admin/navettes"],
          icon: <DirectionsBus/>,
        },
      };

    if (!user || noContainer) return null;

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
        }}
        open={this.state.open}
      >
        <div className={classes.toolbar}>
          <IconButton
            onClick={this.props.handleLeftMenuToggle}
            className={classes.iconButton}
            title="Replier le menu"
          >
            <ChevronLeftIcon/>
          </IconButton>
        </div>
        <Divider/>
        <List>
          <div>
            <LeftMenuButton
              button={buttons.sejours}
            />
          </div>
          <div>
            <LeftMenuButton
              button={buttons.signatures}
            />
          </div>
          <div>
            <LeftMenuButton
              button={buttons.dossiers}
            />
          </div>
          <div>
            <LeftMenuButton
              button={buttons.navettes}
            />
          </div>
        </List>
        <Divider/>
        <div className={classes.copyrightContainer}>
          <Typography
            title={"© " + new Date().getFullYear() + " Oniti.fr"}
            variant="h6"
            color="inherit"
            className={classes.copyright}
            style={{
              opacity: this.state.open ? '1' : '0',
            }}
          >
            <a href="https://www.oniti.fr">
              <img
                className={classes.logo}
                src={DefaultLogo}
                alt={"© " + new Date().getFullYear() + " Oniti.fr"}
              />
            </a>
          </Typography>
          <Typography
            title={"© " + new Date().getFullYear() + " Oniti.fr"}
            variant="h6"
            color="inherit"
            className={classes.copyrightSimple}
            style={{
              opacity: this.state.open ? '0' : '1',
            }}
          >
            <a href="https://www.oniti.fr">
              <img
                className={classes.logoSimple}
                src={LogoArbre}
                alt={"© " + new Date().getFullYear() + " Oniti.fr"}
              />
            </a>
          </Typography>
        </div>
      </Drawer>
    );
  }
}

LeftMenu = withStyles(LeftMenuCss, {withTheme: true})(LeftMenu);
LeftMenu = connect((store) => {
  return {
    user: store.auth.user,
  }
})(LeftMenu);

export default LeftMenu
