import {themeComplement} from '../../AppAdmin/css/theme'

export default theme => ({
  icon: {
    fontSize: '38px',
    color: themeComplement.palette.appBar.color,
  },
  postScriptum: {
    fontSize: '0.9em',
    marginTop: '10px',
  },
  errorMessage: {
    color: 'red',
    marginTop: '10px',
  },
})
