import {axiosClient} from '../../../../axios'
import {addNotification, axiosErrorHandler} from '../../../../reducers/notificationReducer'
import {themeComplement} from '../../../admin/AppAdmin/css/theme'
import {allRoutes} from '../../../routes.js'
import {isMatchingRoute} from '../../../../router/Tools';
import AppConfig from '../../../config';

/**
 * ###########################################################################
 * ##########################  FUNCTIONS #####################################
 * ###########################################################################
 */
export function addPathRoute(dispatch, route) {
  dispatch({
    type: 'APP_PATH_ROUTE_HISTORY',
    payload: route,
  })
}

export function updateOnlineStatus(dispatch, isOnline) {
  dispatch({
    type: 'UPDATE_ONLINE_STATUS',
    payload: isOnline,
  })
}

export function updateReconciliations(dispatch, reconciliations) {
  dispatch({
    type: 'WAITING_RECONCILIATIONS',
    payload: reconciliations,
  })
}

export function changeLang(dispatch, newLang) {
  dispatch({
    type: 'CHANGE_LANG',
    payload: newLang,
  })
}

export function getCampagne(dispatch, cb) {
  let promise = axiosClient.get(`/campagne`)

  promise.then((response) => {
    dispatch({
      type: 'GET_CAMPAGNE',
      payload: response.data,
    });
    if (!!cb) cb(response.data)
  }).catch(error => {
    axiosErrorHandler(dispatch, error);
    if (!!error.response && error.response.status === 404) {
      addNotification(dispatch, {
        message: 'Campagne non trouvée',
        bgColor: themeComplement.palette.notifications.error.color,
      })
    } else if (!!error.response &&
      !!error.response.data &&
      !!error.response.data.errors) {
      dispatch({
        type: 'ERRORS',
        collection: 'campagne',
        payload: error.response.data.errors,
      })
    }
  })
}

export function updateCampagne(dispatch, action, data, cb) {
  let promise = null

  if (action === 'create') promise = axiosClient.post(`/campagne/`, data)
  else promise = axiosClient.put(`/campagne/` + data.uuid, data)

  promise.then((response) => {
    if (typeof response.data !== 'object') throw new Error('Format de réponse erroné');
    dispatch({
      type: 'UPDATE_CAMPAGNE',
      payload: response.data,
    });

    addNotification(dispatch, {
      message: 'Campagne modifiée',
      bgColor: themeComplement.palette.notifications.success.color,
    })

    if (!!cb) cb(response.data)
  }).catch(error => {
    axiosErrorHandler(dispatch, error);
    if (!!error.response && error.response.status === 404) {
      addNotification(dispatch, {
        message: 'Campagne non trouvée',
        bgColor: themeComplement.palette.notifications.error.color,
      })
    } else if (!!error.response &&
      !!error.response.data &&
      !!error.response.data.errors) {
      dispatch({
        type: 'ERRORS',
        collection: 'campagne',
        payload: error.response.data.errors,
      })
    }
  })
}

export function multiplesCampagne(dispatch, campagneUuid, cb) {
  axiosClient.post('campagne/' + campagneUuid + '/multiples')
    .then(() => {
      getCampagne(dispatch, cb)
    })
    .catch(() => {
      addNotification(dispatch, {
        message: 'Une erreur est survenue.',
        bgColor: themeComplement.palette.notifications.error.color,
      })
    })
}

/**
 * ###########################################################################
 * ##########################  REDUCER   #####################################
 * ###########################################################################
 */

let initialState = {
  pathRoutesHistory: [],
  currentRouteConfig: null,
  online: !AppConfig.enableOffLineMode || (AppConfig.enableOffLineMode && navigator.onLine),
  waitingReconciliations: null,
  lang: 'fr',
  campagne: {
    uuid: null,
    date_ouverture: null,
    date_fermeture: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LANG': {
      return {
        ...state,
        lang: action.payload,
      };
    }
    case 'WAITING_RECONCILIATIONS': {
      return {
        ...state,
        waitingReconciliations: action.payload,
      };
    }
    case 'LOGOUT': {
      return {...state, pathRoutesHistory: []};
    }
    case 'UPDATE_ONLINE_STATUS': {
      return {
        ...state,
        online: action.payload,
      };
    }
    case 'APP_PATH_ROUTE_HISTORY': {
      let pathRoutesHistory = state.pathRoutesHistory,
        routeConfig = allRoutes.find(routeConfig => isMatchingRoute(routeConfig.path, action.payload));

      routeConfig = {
        path: !!routeConfig ? routeConfig.path : pathRoutesHistory,
        public: !!routeConfig ? routeConfig.public : true,
        noContainer: !!routeConfig ? !!routeConfig.noContainer : true,
      };
      pathRoutesHistory.unshift(action.payload);

      return {
        ...state,
        pathRoutesHistory: pathRoutesHistory,
        currentRouteConfig: routeConfig,
      };
    }
    case 'GET_CAMPAGNE':
    case 'UPDATE_CAMPAGNE': {
      return {
        ...state,
        campagne: action.payload,
      };
    }
    default:
      return state
  }
}
