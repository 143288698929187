export default theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    float: 'right',
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em',
  },
  addButtonIcon: {
    fontSize: '32px',
  },
  error: {
    color: theme.palette.error.main,
  },
  selectContainer: {
    width: '100%',
  },
  exportBtn: {
    backgroundColor: theme.palette.primary.main,
    padding: '0.5rem',
    textDecoration: 'none',
    color: '#FFFFFF',
  },
});
