import {themeComplement} from './theme';

export default theme => ({
  flex: {
    flex: 1,
  },
  hide: {
    display: 'none',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
    color: themeComplement.palette.appBar.color,
  },
  appBarShift: {
    zIndex: 1201,
    marginLeft: themeComplement.palette.leftBarDefaultWidth,
    width: `calc(100% - ${themeComplement.palette.leftBarDefaultWidth}px)`, // Permet d'ajuster la largeur de la topBar
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  accountIcon: {
    fontSize: '38px',
  },
  avatar: {
    backgroundColor: themeComplement.palette.tercerary,
    borderRadius: '5px',
    height: '2.2em',
    width: '2.2em',
    cursor: 'pointer',
  },
  logo: {
    maxHeight: '45px',
    marginTop: '0.5em',
  }
})
