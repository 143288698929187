import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
  AppBar,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import {Fingerprint, Menu as MenuIcon} from '@material-ui/icons';
import {logout, passwordChange} from '../../../reducers/authReducer';
import Exports from '../Exports'
import CampagneReservations from '../Parametres/CampagneReservations'
import TopBarCss from './css/TopBarCss';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Settings from '@material-ui/icons/Settings';
import Exit from '@material-ui/icons/ExitToApp';
import classNames from 'classnames';
import Logo from '../../../assets/img/logo.png';
import Modal from '@oniti/oniti-modal';
import {TextInput} from '@oniti/oniti-inputs';
import Multiples from '../Multiples';

class TopBar extends Component {
  state = {
    anchorEl: null,
    open: true,
    openModal: false,
    new_password: '',
    password_confirm: '',
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.leftMenuOpen !== prevState.open) {
      return {
        ...prevState,
        open: nextProps.leftMenuOpen,
      };
    }

    return null;
  }

  handleMenu = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handleParams = () => {
    this.props.history.push('/admin/parametres')
  };

  /**
   * Handler du logout
   */
  onClickLogout() {
    logout(this.props.dispatch);

    this.setState({anchorEl: null});

    this.props.history.push('/admin/auth')
  }

  /**
   * Bouton de logout
   * @param {*} classes
   */
  getProfileBtn(classes) {
    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);

    return (
      <>
        <CampagneReservations/>
        <Multiples/>
        <Exports/>
        <IconButton
          onClick={this.handleParams}
          color={this.props.app.currentRouteConfig.path === '/admin/parametres' ? "primary" : "inherit"}
          title="Paramètres"
        >
          <Settings className={classes.accountIcon}/>
        </IconButton>
        <IconButton
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <AccountCircle className={classes.accountIcon}/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.onChangePassword.bind(this)}>
            <Fingerprint/>
            Changer de mot de passe
          </MenuItem>
          <MenuItem onClick={this.onClickLogout.bind(this)}>
            <Exit/>
            Déconnexion
          </MenuItem>
        </Menu>
      </>
    )
  }

  onClickLogoHandler() {
    this.props.history.push('/admin')
  }

  /**
   * Click sur le bouton de changer de mot de passe
   * @return {[type]} [description]
   */
  onChangePassword() {
    this.setState({openModal: true, anchorEl: null})
  }

  /**
   * fermeture de la modal de changement de password
   * @return {[type]} [description]
   */
  onCloseModalHandler() {
    this.setState({openModal: false})
  }

  /**
   * Soumission du formulaire de changement de mot de passe
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {new_password, password_confirm} = this.state
    passwordChange(this.props.dispatch, {new_password, password_confirm}, () => {
      this.onCloseModalHandler()
    })
  }

  /**
   * Handler de changement sur les champs passwords
   * @param  {[type]} name [description]
   * @param  {[type]} e    [description]
   * @return {[type]}      [description]
   */
  onChangeHandler(name, e) {
    this.setState({
      [name]: e.target.value,
    })
  }

  /**
   * Retourne la modal pour changer de mot de passe
   * @return {[type]} [description]
   */
  getModalChangePassword() {
    const {openModal, new_password, password_confirm} = this.state

    if (!openModal) return null;

    return (
      <Modal
        openModal={openModal}
        onCloseHandler={this.onCloseModalHandler.bind(this)}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle key="title" id="alert-dialog-slide-title">
          <Fingerprint/> <span>Changer de mot de passe</span>
        </DialogTitle>
        <DialogContent key="content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Nouveau mot de passe"
                value={new_password}
                margin="normal"
                collectionStore={this.props.auth}
                name='new_password'
                type="password"
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Confirmation"
                value={password_confirm}
                margin="normal"
                collectionStore={this.props.auth}
                name='password_confirm'
                type="password"
                onChangeHandler={this.onChangeHandler.bind(this)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Modal>
    );
  }

  /**
   * Fonction de rendu final du composant
   */
  render() {
    const {classes, noContainer, auth} = this.props;
    if (!auth.user || noContainer) return null;

    return (
      <AppBar
        position="absolute"
        className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
      >
        <Toolbar>
          <IconButton
            className={classNames(classes.menuButton, this.state.open && classes.hide)}
            color="inherit"
            aria-label="Menu"
            title="Déplier le menu"
            onClick={this.props.handleLeftMenuToggle}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            <img
              className={classes.logo}
              src={Logo}
              onClick={this.onClickLogoHandler.bind(this)}
              alt="Accoord | Mercure"
            />
          </Typography>
          {this.getProfileBtn(classes)}
        </Toolbar>
        {this.getModalChangePassword()}
      </AppBar>
    );
  }
}

TopBar = connect((store) => {
  return {
    auth: store.auth,
    app: store.app
  }
})(TopBar);

TopBar = withStyles(TopBarCss, {withTheme: true})(TopBar);
TopBar = withRouter(TopBar);

export default TopBar
