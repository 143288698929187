import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

class AppHome extends Component {
  render() {
    return (
      <div>
        <h1>Environnement de développement</h1>
        <ul>
          <li><a href="/admin">Admin</a></li>
          <li><a href="http://localhost:8000">Partie publique</a></li>
        </ul>
      </div>
    );
  }
}

AppHome = withRouter(AppHome);
export default AppHome;
