import {CircularProgress, DialogContent, DialogTitle, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {collectionActions, loadCollectionAttribute, setReconciliation} from "../../../../reducers/collectionsReducer";
import Modal from '@oniti/oniti-modal';
import CollectionCrudCss from './css/CollectionCrudCss';
import AppConfig from '../../../config';

class CreateUpdate extends Component {
  state = {
    detail: (this.props.defaultValues ? this.props.defaultValues : {}),
    deltailProps: null,
    prevProps: null,
  };

  /**
   * Retourne le nouveau state en fonction des nextProps
   * @param  {[type]} nextProps [description]
   * @param  {[type]} prevState [description]
   * @return {[type]}           [description]
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const {collectionsStore, collectionName, canLoadDetailFromCache, online, localStorageDataKey} = nextProps
    let propsFollowed = ['openModal', 'uuidSelected', 'extraDatasForm', 'defaultValues'],
      collectionNameStore = collectionsStore[collectionName],
      prevProps = {detail: collectionNameStore.detail},
      detail = collectionNameStore.detail

    Object.keys(nextProps)
      .filter(k => propsFollowed.includes(k))
      .forEach(k => prevProps[k] = nextProps[k])
    let newState = {...prevState, prevProps: prevProps}

    if (online) { //Si on est en ligne on appel le back
      loadCollectionAttribute(
        nextProps.dispatch,
        'detail',
        collectionName,
        collectionNameStore,
        {uuid: nextProps.uuidSelected},
      )
    } else if (canLoadDetailFromCache && AppConfig.enableOffLineMode) { // Si on peut récupérer depuis le local et qu'on est hors ligne alors on va les chercher
      let localStorageData = localStorage.getItem(localStorageDataKey)
      if (localStorageData) {
        localStorageData = JSON.parse(localStorageData)
        detail = localStorageData.find(o => o.uuid === nextProps.uuidSelected)
      }
    }

    if (JSON.stringify(detail) !== JSON.stringify(prevState.deltailProps)) {
      newState = {
        ...newState,
        detail: detail ? detail : {},
        deltailProps: detail,
      };
    }

    if (!nextProps.uuidSelected && JSON.stringify(prevState.detail) === JSON.stringify({}) && !!nextProps.defaultValues) {
      newState = {
        ...newState,
        detail: nextProps.defaultValues,
      };
    }

    // Ne pas mettre a jour le state par ce biais si les props n'ont pas changé
    // Cette méthode est appelée suite a l'update du state également
    if (prevState.prevProps === newState.prevProps) {
      newState = null
    } else {
      if (nextProps.detailStateCallback) {
        nextProps.detailStateCallback(newState.detail);
      }
    }
    return newState
  }

  /**
   * Handler du changement sur les inputs
   * @param name
   * @param event
   */
  onChangeHandler(name, event) {
    this.setState({
      detail: {
        ...this.state.detail,
        [name]: event.target.value,
      },
    });
  }

  /**
   * Handler sur la modification
   * @return {[type]} [description]
   */
  onSubmitHandler() {
    const {extraDatasForm, collectionName, dispatch, actionsCallback, online} = this.props;
    let data = this.state.detail;
    if (!!extraDatasForm) {
      data = {...data, ...extraDatasForm}
    }
    let action = !!data.uuid ? 'UPDATE' : 'CREATE'
    if (online) {
      collectionActions(dispatch, collectionName, action, data, (detail) => {
        this.props.onCloseHandler();
        if (actionsCallback) actionsCallback(!!data.uuid ? 'update' : 'create', detail)
      })
    } else if (AppConfig.enableOffLineMode) {
      setReconciliation(dispatch, collectionName, action, data)
      this.props.onCloseHandler();
    }
  }

  /**
   * Permet d'initialiser le state par défaut en cas de valeur préchargés
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  initialize(data) {
    // eslint-disable-next-line
    this.state.detail = {...this.state.detail, ...data}
  }

  /**
   * retourne le formulaire ou un loader
   * @return {[type]} [description]
   */
  getFormOrLoader() {
    const {collectionsStore, collectionName, createUpdateModalContent} = this.props;
    let collectionNameStore = collectionsStore[collectionName];
    let {detail} = collectionNameStore;
    if ((!detail && collectionNameStore.fetching) || !this.state.detail) return <CircularProgress
      className={this.props.classes.progress}/>;
    else {
      return createUpdateModalContent(this.state.detail, collectionNameStore, this.onChangeHandler.bind(this), this.initialize.bind(this));
    }
  }

  /**
   * Retourne le contenu de la modal
   * @return {[type]} [description]
   */
  getModalContent() {
    const {createUpdateModalTitle, classes} = this.props;
    let {detail} = this.state;
    //Pallier au objets vide
    detail = !detail.uuid ? null : detail

    return [
      <DialogTitle className={classes.modalTitle} key="title" id="alert-dialog-slide-title">
        {createUpdateModalTitle(detail)}
      </DialogTitle>,
      <DialogContent key="content">
        {this.getFormOrLoader()}
      </DialogContent>,
    ];

  }

  /**
   * Rendu Final
   * @return {[type]} [description]
   */
  render() {
    const {
      createUpdateModalAction,
      modalMaxWidth,
      onCloseHandler,
      openModal,
      disabledEnterModal,
      fullScreen
    } = this.props
    if (!openModal) return null;
    return (
      <Modal
        actionOk={createUpdateModalAction}
        openModal={openModal}
        onCloseHandler={onCloseHandler}
        onSubmitHandler={this.onSubmitHandler.bind(this)}
        fullWidth={true}
        maxWidth={!!modalMaxWidth ? modalMaxWidth : 'sm'}
        disabledEnter={disabledEnterModal}
        fullScreen={fullScreen}
      >
        {this.getModalContent()}
      </Modal>
    )
  }
}

CreateUpdate.propTypes = {
  openModal: PropTypes.bool,
  uuidSelected: PropTypes.string,
  onCloseHandler: PropTypes.func.isRequired,
  collectionName: PropTypes.string.isRequired,
  createUpdateModalTitle: PropTypes.func,
  createUpdateModalContent: PropTypes.func,
  createUpdateModalAction: PropTypes.string,
  extraDatasForm: PropTypes.object,
  actionsCallback: PropTypes.func,
  modalMaxWidth: PropTypes.string,
  detailStateCallback: PropTypes.func,
  disabledEnterModal: PropTypes.bool,
  defaultValues: PropTypes.object,
  canLoadDetailFromCache: PropTypes.bool,
  localStorageDataKey: PropTypes.string,
  fullScreen: PropTypes.bool,
};

CreateUpdate = connect((store) => {
  return {
    collectionsStore: store.collections,
    online: store.app.online,
  }
})(CreateUpdate);

export default withStyles(CollectionCrudCss)(CreateUpdate);
