import {Grid, IconButton, Typography, withStyles} from '@material-ui/core'

import React, {Component} from 'react'
import {connect} from 'react-redux'
import CollectionCrud from '../../common/Components/CollectionCrud'
import {NumberInput, TextInput} from '@oniti/oniti-inputs';
import DossiersCss from './css/DossiersCss'
import { collectionActions } from '../../../reducers/collectionsReducer'
import { Payment, DoneAll, HelpOutline, MailOutline, LooksOne } from '@material-ui/icons';

class Dossiers extends Component {
  state = {
    selectedSejour: null,
  }

  componentDidMount() {
    let {dispatch} = this.props;

    collectionActions(
      dispatch,
      'dossiers',
      'INDEX',
    )
  }

  getDateTime(string) {
    if (!string) {
      return ''
    }
    let date = new Date(string)
    let options = {day: 'numeric', month: 'numeric', year: 'numeric'}
    return date.toLocaleTimeString('fr-FR', options)
  }

  getDate(string) {
    if (!string) {
      return ''
    }
    let date = new Date(string)
    let options = {day: 'numeric', month: 'numeric', year: 'numeric'}
    return date.toLocaleDateString('fr-FR', options)
  }

  getCreateUpdateModalTitle(dossier) {
    if (!dossier) {
      return <>
        <Payment/>
        <span>chargement...</span>
      </>
    }

    return <>
      <Payment/>
      <span>Édition du dossier {dossier.parents_nom} {dossier.parents_prenom}</span>
    </>
  }

  getCreateUpdateModalContent(dossier, dossiersStore, onChangeHandler) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="num_dossier_accoord"
            label="N° dossier"
            value={dossier.num_dossier_accoord}
            margin="normal"
            collectionStore={dossiersStore}
            name="num_dossier_accoord"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
        <NumberInput
            id="reste_a_payer"
            label="Reste à payer"
            value={dossier.reste_a_payer}
            margin="normal"
            collectionStore={dossiersStore}
            name="reste_a_payer"
            onChangeHandler={onChangeHandler}
            fullWidth
            disabled={dossier.num_dossier_accoord ? false : true}
            helperText="Il est impératif de renseigner le numéro de dossier pour pouvoir saisir le reste à payer."
          />
        </Grid>
      </Grid>
    )
  }

  getStatus(dossier) {
    const slug = dossier.statut.split('-')[1]

    switch (slug) {
      case 'done':
        return <DoneAll titleAccess="terminé"/>
      case 'missing':
        return <HelpOutline titleAccess="numéro de dossier manquant"/>
      case 'mail':
        return <MailOutline titleAccess="attente réponse parent suite au mail"/>
      default:
    }
  }

  getPaiements(dossier) {
    const {classes} = this.props

    var amounts = [];
    dossier.paiements.forEach((paiement) => {
      amounts.push(paiement.amount_str)
    })

    const comp = amounts.join(' + ')

    return (
      <span title={comp} className={(amounts.length > 1) ? classes.details : ''}>
        {dossier.total_paiements_str}
      </span>
    )
  }

  getDossiersUrlsButton(dossier) {
    return (
      <>
        <IconButton
          aria-label="lien de paiement parent"
          color="primary"
          title="lien de paiement parent"
          key={"form-dossier-" + dossier.uuid}
          disabled={dossier.statut.split('-')[1] !== 'mail'}
          onClick={() => window.open(dossier.url, '_blank')}
        >
          <LooksOne/>
        </IconButton>
      </>
    )
  }

  render() {
    const {classes} = this.props

    return (
      <Grid container spacing={2} className={classes.container}>
        <Typography
          variant="button"
          gutterBottom
          className={classes.sectionTitle}
        >
          Dossiers de paiement
        </Typography>

        <CollectionCrud
          collectionName={'dossiers'}
          loadDatas={false}
          showBtnEdit={true}
          showBtnAdd={false}
          showBtnDelete={false}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          additionnalControllers={[
            this.getDossiersUrlsButton.bind(this),
          ]}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            rowsPerPageOptions: [20, 50, 100],
            rowClassName: (row) => {
              const slug = row.statut.split('-')[1]

              switch(slug) {
                case 'done':
                  return classes.allDone
                case 'missing':
                  return classes.missingNumDoss
                case 'mail':
                  return classes.waitingParent
                default:
                  return ''
              }
            }
          }}
          cellsConfig={[
            {
              datakey: 'statut',
              title: 'Statut',
              sortable: true,
              searchable: false,
              format: this.getStatus,
            },
            {
              datakey: 'num_dossier_accoord',
              title: 'N° dossier',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'parents_nom',
              title: 'Nom parent',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'parents_prenom',
              title: 'Prénom parent',
              sortable: true,
              searchable: true,
            },
            {
              datakey: 'enfants',
              title: 'Enfant(s)',
              sortable: false,
              searchable: false,
              format: (obj, key) => {
                return '(' + obj[key].length + ') ' + obj[key].map((enfant) => {
                  return `${enfant.nom} ${enfant.prenom}`
                }).join(' / ')
              }
            },
            {
              datakey: 'sejours_arr',
              title: 'Séjours',
              sortable: false,
              searchable: false,
              format: (obj, key) => {
                return obj[key].map((sejour) => {
                  return `${sejour.libelle}`
                }).join(' / ')
              }
            },
            {
              datakey: 'total_paiements_str',
              title: 'Total réglé',
              sortable: true,
              searchable: true,
              className: classes.currency,
              format: this.getPaiements.bind(this),
            },
            {
              datakey: 'reste_a_payer_str',
              title: 'Reste à payer',
              sortable: true,
              searchable: true,
              className: classes.currency,
            },
          ]}
        />
      </Grid>
    );
  }
}

Dossiers = withStyles(DossiersCss)(Dossiers)

Dossiers = connect((store) => {
  return {
    dossiersStore: store.collections.dossiers,
  }
})(Dossiers);

export default Dossiers
