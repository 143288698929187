export default theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    float: 'right',
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em',
    color: '#FFFFFF',
  },
  addButtonIcon: {
    fontSize: '32px',
  },
  error: {
    color: theme.palette.error.main,
  },
  divider: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  offLineDataTime: {
    textAlign: 'center',
    padding: '5px',
    marginTop: '10px',
    color: '#ea6153',
    fontWeight: 'bold',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  offLineReconciliations: {
    padding: '5px',
    marginTop: '10px',
    color: '#ea6153',
    fontWeight: 'bold',
  },
  crudTd: {

  }
});
